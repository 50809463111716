import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { withStore } from '../../../store/rootStore';
import { has } from 'lodash';
import Compose from '../Compose';
import Controls from './Controls';
import Preview from './Preview';
import ModalWithoutBottom from '../common/ModalWithoutBottom';
import useCheckIfMobile from '../../../helpers/hooks/useCheckIfMobile';
import Document from '../../../components/icons/document';
import Image from '../../../components/icons/image';
import Video from '../../../components/icons/video';
import s from './FilePreview.module.scss';
const FilePreview = ({ store, activeChat, composeProps }) => {
    var _a, _b, _c;
    const [pageHeight, setPageHeight] = useState(0);
    const innerRef = useRef(null);
    const isMobile = useCheckIfMobile();
    const { filePreview, toggleFilePreview, filesData, theme, activeReplyMessage } = store.activeChatStore;
    const [selectedFile, setSelectedFile] = useState();
    const adapter = activeChat
        ? store.channels.getAccountAdapter({
            id: activeChat.channelInfo.accountId,
            name: activeChat.channelInfo.name,
        })
        : null;
    const mediaLimits = adapter === null || adapter === void 0 ? void 0 : adapter.messageLimits.media;
    const skipCaption = has(mediaLimits === null || mediaLimits === void 0 ? void 0 : mediaLimits[(selectedFile === null || selectedFile === void 0 ? void 0 : selectedFile.type) || ''], 'caption')
        ? !((_a = mediaLimits === null || mediaLimits === void 0 ? void 0 : mediaLimits[(selectedFile === null || selectedFile === void 0 ? void 0 : selectedFile.type) || '']) === null || _a === void 0 ? void 0 : _a.caption)
        : !((_b = mediaLimits === null || mediaLimits === void 0 ? void 0 : mediaLimits.document) === null || _b === void 0 ? void 0 : _b.caption);
    const disabledMessage = skipCaption ? 'captionNotAvailable' : null;
    const captionMaxLength = (_c = mediaLimits === null || mediaLimits === void 0 ? void 0 : mediaLimits[(selectedFile === null || selectedFile === void 0 ? void 0 : selectedFile.type) || '']) === null || _c === void 0 ? void 0 : _c.captionMaxLength;
    const composeButtonRef = useRef(null);
    const onPDFLoadSuccess = () => {
        var _a;
        const containerHeight = (((_a = innerRef.current) === null || _a === void 0 ? void 0 : _a.clientHeight) || 0) * 0.9 - 12;
        setPageHeight(containerHeight);
    };
    const onKeyDown = (e) => {
        var _a;
        if (!filePreview)
            return;
        if (e.key === 'Escape') {
            toggleFilePreview(false);
        }
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            (_a = composeButtonRef.current) === null || _a === void 0 ? void 0 : _a.click();
        }
    };
    useEffect(() => {
        window.addEventListener('keydown', onKeyDown);
        return () => {
            window.removeEventListener('keydown', onKeyDown);
        };
    }, [filePreview]);
    useEffect(() => {
        if ((filesData === null || filesData === void 0 ? void 0 : filesData.files) && (filesData === null || filesData === void 0 ? void 0 : filesData.files.length) > 0) {
            setSelectedFile({ file: filesData.files[0].file, type: filesData.files[0].type });
        }
    }, [filesData]);
    if (!filePreview || !filesData)
        return null;
    const caption = filesData.caption;
    const sendFile = (message) => {
        if (filesData) {
            const caption = skipCaption ? '' : message;
            const messageQuotedId = activeReplyMessage === null || activeReplyMessage === void 0 ? void 0 : activeReplyMessage.channelInfo.id;
            const filesFormatted = filesData === null || filesData === void 0 ? void 0 : filesData.files.map((file) => {
                return {
                    file: file.file,
                    type: file.type,
                    name: file.file.name,
                };
            });
            store.activeChatStore.sendMediaMessage(filesFormatted, caption, messageQuotedId);
            composeProps.onChange('');
            toggleFilePreview(false);
        }
    };
    const onRemoveMedia = (fileUid) => {
        store.activeChatStore.removeFile(fileUid);
    };
    const onAddMedia = (files) => {
        store.activeChatStore.addFiles(files);
    };
    const renderIconByExt = (ext) => {
        switch (ext) {
            case 'pdf':
                return React.createElement(Document, { color: theme.color });
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
                return React.createElement(Image, { color: theme.color });
            case 'mp4':
            case 'mov':
            case 'avi':
                return React.createElement(Video, { color: theme.color });
            default:
                return React.createElement(Document, { color: theme.color });
        }
    };
    return (React.createElement(ModalWithoutBottom, { setModalVisible: () => toggleFilePreview(false), titleTextKey: 'filePreviewTitle', titleTextValues: { count: (filesData === null || filesData === void 0 ? void 0 : filesData.files.length) || '' }, height: isMobile ? '90vh' : '650px', width: isMobile ? undefined : 1200, visible: true },
        React.createElement("div", { className: s.container },
            React.createElement("div", { ref: innerRef, className: s.inner }, selectedFile && (React.createElement(Preview, { file: selectedFile.file, type: selectedFile.type, pageHeight: pageHeight, onPDFLoadSuccess: onPDFLoadSuccess, renderIconByExt: renderIconByExt }))),
            React.createElement(Controls, { files: filesData.files, selectedFileUid: selectedFile === null || selectedFile === void 0 ? void 0 : selectedFile.file.uid, onAdd: onAddMedia, onRemove: onRemoveMedia, onSelect: (file, type) => setSelectedFile({ file, type }), mediaLimits: mediaLimits, renderIconByExt: renderIconByExt }),
            !!activeChat && (React.createElement("div", { className: s.actionBar },
                React.createElement("div", { className: s.composeWrapper },
                    React.createElement(Compose, Object.assign({}, composeProps, { sendTextMessageCB: sendFile, activeChat: activeChat, disabled: !!disabledMessage, disabledMessage: disabledMessage, initialText: caption, theme: theme, textMaxLength: captionMaxLength, buttonRef: composeButtonRef, maxRows: 6, onQuickReplySelected: () => { }, onFilesDrop: () => { }, currentChatId: null, previewMode: true }))))))));
};
export default withStore(observer(FilePreview));
