import React, { useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { Typography } from 'antd';
import Icon from '@ant-design/icons';
import { Trans, useTranslation } from 'react-i18next';
import classnames from 'classnames';
import hebrewCheck from '../../../../../Base/Utils/HebrewCheck';
import GeneratorLabel from '../../GeneratorLabel';
import { withStore } from '../../../../../../store/rootStore';
import { CALL_TO_ACTION_TYPES, DEFAULT_EXAMPLE_TEMPLATE_IMG_URL, RICH_TEMPLATE_BUTTON_TYPE, } from '../../../../../../constants';
import defaultBackground from '../../../../../../assets/themePresetsChannel/backgrounds/defaultBackground.jpeg';
import defaultImage from '../../../../../../assets/icons/imagePreview/defaultImage.png';
import defaultFile from '../../../../../../assets/icons/file/defaultFile.png';
import defaultVideo from '../../../../../../assets/icons/file/defaultVideo.mp4';
import { ReactComponent as Link } from '../../../../../../assets/icons/arrows/export/externalLink.svg';
import { ReactComponent as Phone } from '../../../../../../assets/icons/noGroupIcons/phoneCallSvg.svg';
import { TEMPLATE_HEADER_TYPE } from '@whatsper/texterchat-common';
import MessageMarkdown from '../../../../../Base/Utils/MessageMarkdown/MessageMarkdown';
import styles from './MessagePreview.module.scss';
const { Text } = Typography;
const MessagePreview = ({ header, body, footer, buttons, store, canCollapse }) => {
    const [collapsable, setCollapsable] = useState(!!canCollapse);
    const [collapsed, setCollapsed] = useState(false);
    const contentRef = useRef(null);
    const { t } = useTranslation();
    const textWithParams = () => {
        let { text, params } = body;
        if (params) {
            params.forEach((param, i) => {
                if (param) {
                    text = text.replace(`{{${i + 1}}}`, param);
                }
            });
        }
        return text;
    };
    const getImagePreviewSrc = () => {
        if (header.previewUrl)
            return header.previewUrl;
        if ([TEMPLATE_HEADER_TYPE.IMAGE].includes(header.type) && header.file) {
            return URL.createObjectURL(header.file);
        }
        if (header.url)
            return header.url;
        return defaultImage;
    };
    const getVideoPreviewSrc = () => {
        if (header.previewFile)
            return URL.createObjectURL(header.previewFile);
        if ([TEMPLATE_HEADER_TYPE.VIDEO].includes(header.type) && header.file) {
            return URL.createObjectURL(header.file);
        }
        if (header.url && header.url !== DEFAULT_EXAMPLE_TEMPLATE_IMG_URL)
            return header.url;
        return defaultVideo;
    };
    const getBackground = useCallback(() => {
        var _a, _b;
        const { color } = (_b = (_a = store.channels) === null || _a === void 0 ? void 0 : _a.themes) === null || _b === void 0 ? void 0 : _b.whatsapp;
        return `linear-gradient(${color}50, ${color}50), url(${defaultBackground})`;
    }, []);
    const getHeader = useCallback(() => {
        var _a;
        if (header.type === TEMPLATE_HEADER_TYPE.IMAGE) {
            return React.createElement("img", { src: getImagePreviewSrc(), className: styles.image, alt: t('imagePreview') });
        }
        if (header.type === TEMPLATE_HEADER_TYPE.VIDEO) {
            return (React.createElement("video", { src: getVideoPreviewSrc(), className: styles.video, controls: true },
                React.createElement("source", { src: getVideoPreviewSrc(), type: (_a = header.file) === null || _a === void 0 ? void 0 : _a.type })));
        }
        if (header.type === TEMPLATE_HEADER_TYPE.DOCUMENT) {
            return React.createElement("img", { src: defaultFile, className: styles.image, alt: t('filePreview') });
        }
        if (header.type === TEMPLATE_HEADER_TYPE.TEXT) {
            return (React.createElement(Text, { className: classnames(styles.text, {
                    [styles.rtl]: hebrewCheck(body.text),
                }) }, header === null || header === void 0 ? void 0 : header.text));
        }
        return null;
    }, [header]);
    const getBody = () => {
        return (React.createElement(Text, { className: classnames(styles.text, {
                [styles.rtl]: hebrewCheck(body.text),
            }) },
            React.createElement(MessageMarkdown, { text: body.exampleText || textWithParams() })));
    };
    const getFooter = () => {
        return (React.createElement(Text, { className: classnames(styles.text, styles.footerText, {
                [styles.rtl]: hebrewCheck(body.text),
            }) }, footer === null || footer === void 0 ? void 0 : footer.text));
    };
    const onShowMoreClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setCollapsed(false);
        setCollapsable(false);
    };
    useEffect(() => {
        if (contentRef.current && collapsable && !collapsed) {
            const { scrollHeight, parentElement } = contentRef.current;
            /* Content is scrollable */
            if (parentElement && scrollHeight > parentElement.clientHeight) {
                setCollapsed(true);
            }
        }
    }, [contentRef.current, collapsable, collapsed]);
    useEffect(() => {
        setCollapsable(!!canCollapse);
    }, [canCollapse]);
    let quickReplyButtons, callToActionButtons;
    if (buttons) {
        if (buttons.type === RICH_TEMPLATE_BUTTON_TYPE.QUICK_REPLY) {
            quickReplyButtons = buttons.buttons;
        }
        else {
            callToActionButtons = buttons.buttons;
        }
    }
    return (React.createElement("div", { className: styles.previewContainer },
        React.createElement(GeneratorLabel, { textKey: "preview" }),
        React.createElement("div", { className: styles.preview, style: { background: getBackground() } },
            React.createElement("div", { className: styles.messageBlock },
                React.createElement("div", { className: styles.message },
                    React.createElement("div", { className: classnames(styles.innerBlock, {
                            [styles.collapsable]: collapsable,
                            [styles.collapsed]: collapsed,
                        }) },
                        React.createElement("div", { className: classnames(styles.innerTopBlock, {
                                [styles.rtl]: hebrewCheck(body.text),
                            }), ref: contentRef },
                            React.createElement("div", { className: styles.header }, getHeader()),
                            React.createElement("div", { className: styles.body }, getBody()),
                            React.createElement("div", { className: styles.footer }, getFooter())),
                        callToActionButtons && (React.createElement("div", { className: styles.actionButtons }, callToActionButtons.map((button, i) => (React.createElement("div", { key: i, className: styles.actionButton },
                            React.createElement(Icon, { className: styles.actionIcon, component: button.type === CALL_TO_ACTION_TYPES.PHONE_NUMBER ? Phone : Link }),
                            React.createElement(Text, { className: classnames(styles.text, styles.actionText, {
                                    [styles.rtl]: hebrewCheck(body.text),
                                }) }, button.text)))))),
                        collapsed && (React.createElement("div", { className: styles.collapsableButton, onClick: onShowMoreClick },
                            React.createElement(Trans, { i18nKey: "showMore" })))),
                    quickReplyButtons && (React.createElement("div", { className: styles.quickButtons }, [...quickReplyButtons].reverse().map((button, i) => (React.createElement("div", { key: i, className: classnames(styles.quickButton, {
                            [styles.nonSingleButton]: quickReplyButtons.length > 1 && i < 2,
                        }) },
                        React.createElement(Text, { className: classnames(styles.text, {
                                [styles.rtl]: hebrewCheck(body.text),
                            }) }, button.text)))))))))));
};
export default withStore(observer(MessagePreview));
