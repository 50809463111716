import React from "react";
import './DepartmentItemStyles.css';

import {ReactComponent as SingleCheck} from "../../../../assets/icons/chatStatuses/singleCheck.svg";
import Icon from '@ant-design/icons';
import { Trans } from "react-i18next";

const DepartmentItem = (props) => {
  const { department, isActive, setDepartment } = props;
  
  return (
    <div
      className={isActive ? 'conversation-list-item department-item department-item__active' : 'department-item conversation-list-item'}
      onClick = {() => setDepartment(department._id)}
    >
      <div className="conversation-photo department-item__avatar" style={{background: department.color}} />
      <div className={'conversation-info'}>
        <div className="conversation-title-wrapper">
          <h1 className="conversation-title">{ department.name }</h1>
          <p className="conversation-message">
            <Trans i18nKey="numberOfAgents" values={{ count: department.agents?.length || 0 }} />
          </p>
        </div>
        {isActive &&
        <div className="conversation-status-block department-item__img-container">
          <Icon component={SingleCheck} className={"department-item__img"} />
        </div>
        }
      </div>
    </div>
  )
}

export default DepartmentItem;
