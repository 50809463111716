import React, { Component } from 'react';
import { observer } from "mobx-react";
import classnames from "classnames";
import { LoadingOutlined, DownOutlined } from '@ant-design/icons';
import { Button, Radio, Divider, Typography } from 'antd';
import { Trans, withTranslation } from "react-i18next";
import blankProfilePicture from "../../../assets/avatars/blankProfilePicture.png";
import { withStore } from '../../../store/rootStore';
import { getDirection } from '../../../helpers';
import "./AcceptChatBlock.scss";
const { Text } = Typography;
class AcceptChatBlock extends Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                loading: false,
                secondaryOptionsOpen: false,
                assignChatToDep: localStorage.getItem('assignChatToDep') || '',
                hovered: false,
            }
        });
        Object.defineProperty(this, "secondaryOptionsRef", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "secondaryOptionsBtnRef", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        // Not doing this on componentDidMount and componentWillUnmount for better performance
        Object.defineProperty(this, "addListeners", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                document.addEventListener('click', this.onDocumentClick);
                // "keypress" work not always for Esc
                document.addEventListener('keyup', this.onDocumentClick);
            }
        });
        Object.defineProperty(this, "removeListeners", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                document.removeEventListener('click', this.onDocumentClick);
                document.removeEventListener('keyup', this.onDocumentClick);
            }
        });
        Object.defineProperty(this, "componentWillUnmount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.removeListeners();
            }
        });
        Object.defineProperty(this, "onHover", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (hovered) => {
                this.setState({ hovered });
            }
        });
        Object.defineProperty(this, "onDocumentClick", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (event) => {
                const t = event.target;
                if (!(
                // Click somewhere inside secondary options 
                (this.secondaryOptionsRef && this.secondaryOptionsRef.contains(t))
                    // or click on open options button or it descendants
                    || (this.secondaryOptionsBtnRef && (this.secondaryOptionsBtnRef.isEqualNode(t) || this.secondaryOptionsBtnRef.contains(t))))) {
                    this.setState({ secondaryOptionsOpen: false }, () => this.removeListeners());
                }
            }
        });
        Object.defineProperty(this, "handleOnClick", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (chatId, departmentId) => {
                var _a;
                this.setState({ loading: true });
                this.props.store.chatsStore.assignChat(chatId, (_a = this.props.store.loginStore.userDetails) === null || _a === void 0 ? void 0 : _a.uid, departmentId)
                    .catch((error) => {
                    console.log("Error: assigning chat to agent", error);
                })
                    .finally(() => {
                    this.setState({ loading: false });
                });
            }
        });
        Object.defineProperty(this, "switchSecondaryOptions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                e.stopPropagation();
                this.secondaryOptionsBtnRef.blur();
                this.setState({ secondaryOptionsOpen: !this.state.secondaryOptionsOpen }, () => {
                    if (this.state.secondaryOptionsOpen) {
                        this.addListeners();
                    }
                    else {
                        this.removeListeners();
                    }
                });
            }
        });
        Object.defineProperty(this, "handleAssignChatToDep", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                this.setState({
                    secondaryOptionsOpen: false,
                    assignChatToDep: value,
                }, () => this.removeListeners());
                localStorage.setItem("assignChatToDep", value);
            }
        });
        Object.defineProperty(this, "getAllAgentDepartments", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { departments } = this.props.store.departmentsStore;
                const { userDetails } = this.props.store.loginStore;
                if (userDetails) {
                    return departments.filter((department) => { var _a; return (_a = department === null || department === void 0 ? void 0 : department.agents) === null || _a === void 0 ? void 0 : _a.includes(userDetails.uid); });
                }
                return [];
            }
        });
        Object.defineProperty(this, "getCurrentAgentDepartment", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { userDetails } = this.props.store.loginStore;
                const agentDepartments = this.getAllAgentDepartments();
                if (userDetails) {
                    return agentDepartments.find((department) => { var _a; return (_a = department === null || department === void 0 ? void 0 : department.agents) === null || _a === void 0 ? void 0 : _a.includes(userDetails.uid); });
                }
                return [];
            }
        });
        Object.defineProperty(this, "getDepartment", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (departmentId) => {
                const agentDepartments = this.getAllAgentDepartments();
                return agentDepartments.find((department) => (department === null || department === void 0 ? void 0 : department._id) === departmentId);
            }
        });
    }
    componentDidUpdate(prevProps) {
        if (prevProps.activeChat !== this.props.activeChat) {
            const department = this.getDepartment(localStorage.getItem('assignChatToDep') || '');
            this.handleAssignChatToDep((department === null || department === void 0 ? void 0 : department._id) || '');
        }
    }
    render() {
        var _a;
        const { i18n, activeChat, store } = this.props;
        const { agentsStore } = store;
        const { secondaryOptionsOpen, assignChatToDep, loading } = this.state;
        const selectedDepartment = this.getDepartment(assignChatToDep);
        return (React.createElement("div", { className: "take-chat-container" },
            React.createElement("div", { className: "info-block" }, activeChat.agent.uid === "" ?
                React.createElement("div", { className: "text-block" })
                :
                    React.createElement("div", { className: "agent-info-block" },
                        React.createElement("img", { className: "agent-avatar", src: blankProfilePicture }),
                        React.createElement("div", { className: "agent-info" },
                            React.createElement("div", { className: "agent-name" }, activeChat.agent.uid ?
                                agentsStore.getAgentByUid(activeChat.agent.uid) :
                                React.createElement(Trans, { i18nKey: "bot" })),
                            React.createElement("div", { className: "agent-status" },
                                React.createElement(Trans, { i18nKey: "agent" }))))),
            React.createElement("div", { className: "buttons-container", onMouseEnter: () => this.onHover(true), onMouseLeave: () => this.onHover(false) },
                React.createElement(Button, { disabled: loading, type: "primary", className: "big-green-button take-button accept-button", onClick: () => {
                        const departmentId = assignChatToDep || activeChat.departmentId;
                        this.handleOnClick(activeChat._id, departmentId);
                    } }, loading ? (React.createElement(LoadingOutlined, null)) : (React.createElement(React.Fragment, null, assignChatToDep && selectedDepartment ? (React.createElement(React.Fragment, null,
                    React.createElement(Text, { className: "take-chat-button-bold-text" },
                        React.createElement(Trans, { i18nKey: "takeThisChat" })),
                    React.createElement(Text, { className: classnames('take-chat-button-text', {
                            'take-chat-button-text-rtl': getDirection() === 'rtl',
                        }) },
                        React.createElement(Trans, { i18nKey: "andAssignTo", components: {
                                color: (React.createElement("div", { style: {
                                        backgroundColor: selectedDepartment
                                            ? selectedDepartment.color
                                            : 'white',
                                    }, className: classnames('take-chat-department-color', 'take-chat-dep-button-color') })),
                            }, values: { department: selectedDepartment ? selectedDepartment.name : null } })))) : (React.createElement(Trans, { i18nKey: "takeThisChat" }))))),
                React.createElement(Button, { ref: (ref) => {
                        if (ref) {
                            this.secondaryOptionsBtnRef = ref;
                        }
                    }, disabled: loading, className: classnames("big-green-button", "take-chat-secondary-options-button"), onClick: this.switchSecondaryOptions },
                    React.createElement(DownOutlined, null))),
            secondaryOptionsOpen &&
                React.createElement("div", { ref: (ref) => {
                        if (ref) {
                            this.secondaryOptionsBtnRef = ref;
                        }
                    }, className: classnames("take-chat-secondary-options-container", {
                        "rtl": getDirection() === 'rtl',
                    }) },
                    React.createElement(Text, { className: "take-chat-radio-header" },
                        React.createElement(Trans, { i18nKey: "afterClickOnButton" })),
                    React.createElement(Divider, { className: "take-chat-radio-divider" }),
                    React.createElement(Radio.Group, { className: "take-chat-radio-group", onChange: (e) => this.handleAssignChatToDep(e.target.value), value: assignChatToDep },
                        React.createElement(Radio, { className: "take-chat-radio-option", value: "" },
                            React.createElement(Trans, { i18nKey: "onlyTakeChat" })),
                        React.createElement(Divider, { className: "take-chat-radio-divider" }), (_a = this.getAllAgentDepartments()) === null || _a === void 0 ? void 0 :
                        _a.map(({ color, _id, name }) => {
                            return (React.createElement(React.Fragment, { key: _id },
                                React.createElement(Radio, { value: _id, className: "take-chat-radio-option", disabled: !this.getCurrentAgentDepartment() },
                                    React.createElement(Text, { className: "take-chat-radio-option-text" },
                                        React.createElement(Trans, { i18nKey: "takeChatAndAssignTo" })),
                                    React.createElement(Text, { className: "take-chat-radio-option-text" },
                                        React.createElement(Trans, { i18nKey: 'takeChatDep', components: {
                                                color: (React.createElement("div", { style: { backgroundColor: color || 'white' }, className: "take-chat-department-color" })),
                                            }, values: {
                                                department: name || (i18n === null || i18n === void 0 ? void 0 : i18n.t('noDepartment')),
                                            } }))),
                                React.createElement(Divider, { className: "take-chat-radio-divider" })));
                        })))));
    }
}
export default withTranslation()(withStore(observer(AcceptChatBlock)));
