var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component } from 'react';
import { observer } from "mobx-react";
import { Switch, Tag, TimePicker } from 'antd';
import { Trans, withTranslation } from "react-i18next";
import ColorSelector from "../../ColorSelector";
import { withStore } from '../../../store/rootStore';
import { AGENTS_DEPARTMENTS_COLORS, SLA_TIMEPICKER_FORMAT } from '../../../constants';
import { MyInput, MySelect } from '../../Base/Form';
import cross from '../../../assets/messageStatuses/Cross.png';
import classNames from 'classnames';
import styles from "./DepartmentForm.module.scss";
class DepartmentForm extends Component {
    constructor(props) {
        super(props);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                name: '',
                color: AGENTS_DEPARTMENTS_COLORS[0],
                _id: '',
                slaTime: null,
                isSlaEnabled: false,
                idEditDisabled: false,
                agents: [],
            }
        });
        Object.defineProperty(this, "componentDidMount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.props.onInput(this.state, this.canBeSavedState());
                if (this.props.entity && this.props.entity._id)
                    this.setState({ idEditDisabled: true });
            }
        });
        Object.defineProperty(this, "onInput", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (formData) => {
                this.setState(Object.assign({}, formData), () => this.props.onInput(this.state, this.canBeSavedState()));
            }
        });
        Object.defineProperty(this, "canBeSavedState", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                return this.state.name.trim().length > 0;
            }
        });
        Object.defineProperty(this, "getAssignedAgents", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const agentIds = this.state.agents;
                let agents = [];
                if (agentIds && agentIds.length) {
                    agents = this.props.store.agentsStore.users.filter(user => agentIds.includes(user._id));
                }
                return agents;
            }
        });
        Object.defineProperty(this, "getActiveAgents", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                return this.props.store.agentsStore.users.filter(user => !user.disabled && !this.state.agents.includes(user._id));
            }
        });
        Object.defineProperty(this, "assignAgent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (agentId) => __awaiter(this, void 0, void 0, function* () {
                yield this.props.updateDepartmentAgents(this.state._id, agentId, 'add');
                this.setState({ agents: [...this.state.agents, agentId] });
            })
        });
        Object.defineProperty(this, "unassignAgent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (agentId) => __awaiter(this, void 0, void 0, function* () {
                yield this.props.updateDepartmentAgents(this.state._id, agentId, 'remove');
                this.setState({ agents: this.state.agents.filter(id => id !== agentId) });
            })
        });
        if (props.entity) {
            const slaTime = props.entity.sla && props.entity.sla.time !== 0
                ? props.store.dashboard.parseSlaTimeFromMillisecondsToMoment(props.entity.sla.time)
                : null;
            this.state = Object.assign(Object.assign(Object.assign({}, this.state), props.entity), { slaTime, isSlaEnabled: !!slaTime });
        }
    }
    render() {
        const { name, color, _id, slaTime, isSlaEnabled, idEditDisabled, } = this.state, { t, error, loading } = this.props;
        const assignedAgents = this.getAssignedAgents();
        const activeAgents = this.getActiveAgents();
        return (React.createElement(React.Fragment, null,
            !!error &&
                React.createElement("div", { className: styles.addDepartmentError }, error),
            React.createElement("div", { className: styles.addDepartmentLabeledInput },
                React.createElement("label", null,
                    React.createElement(Trans, { i18nKey: "name" })),
                React.createElement(MyInput, { value: name, onChangeHandler: (value) => this.onInput({ name: value }), placeholder: t('enterName') })),
            React.createElement("div", { className: styles.addDepartmentLabeledInput },
                React.createElement("label", null,
                    React.createElement(Trans, { i18nKey: "departmentColor" })),
                React.createElement(ColorSelector, { onChange: (color) => this.onInput({ color }), color: color, colors: AGENTS_DEPARTMENTS_COLORS })),
            React.createElement("div", { className: styles.addDepartmentLabeledInput },
                React.createElement("label", null,
                    React.createElement(Trans, { i18nKey: "departmentId" })),
                React.createElement(MyInput, { value: _id, onChangeHandler: (value) => this.onInput({ _id: value }), disabled: idEditDisabled, placeholder: t("EnterDepartmentId") })),
            React.createElement("div", { className: styles.addDepartmentLabeledInput },
                React.createElement("label", null,
                    React.createElement(Trans, { i18nKey: "setSLATime" })),
                React.createElement("div", { className: styles.slaContainer },
                    React.createElement(Switch, { checked: isSlaEnabled, onChange: (isSlaEnabled) => this.onInput({ isSlaEnabled }), className: styles.slaSwitch }),
                    React.createElement(TimePicker, { value: isSlaEnabled ? slaTime : null, onChange: (slaTime) => this.onInput({ slaTime }), format: SLA_TIMEPICKER_FORMAT, placeholder: t('selectTime'), disabled: !isSlaEnabled, className: styles.slaTimePicker, showSecond: false, showNow: false }))),
            React.createElement("div", { className: styles.addDepartmentLabeledInput },
                React.createElement("label", null,
                    React.createElement(Trans, { i18nKey: "associatedAgents" })),
                React.createElement(MySelect, { value: null, dropdownStyle: { padding: 0 }, loading: loading, disabled: loading, className: styles.addDepartmentSelect, onSelect: this.assignAgent, placeholder: t('searchAgentPlaceholder') }, activeAgents.map((agent, i) => (React.createElement(MySelect.Option, { key: i, value: agent._id },
                    React.createElement("div", { className: styles.selectAgentContainer },
                        React.createElement("div", { style: { backgroundColor: agent.color ? agent.color : "lightgrey" }, className: "select-department-color" }),
                        agent.displayName),
                    agent.roles && agent.roles.length > 0 && (React.createElement("div", { className: styles.addDepartmentRoles }, agent.roles.map(role => (React.createElement(Tag, { key: role, color: "blue" }, role))))))))),
                React.createElement("div", { className: classNames(styles.addDepartmentAgentsList, {
                        [styles.disabled]: loading
                    }) }, assignedAgents.map(agent => (React.createElement("div", { className: styles.addDepartmentAgent, key: agent._id },
                    agent.displayName,
                    React.createElement("img", { className: styles.unassignAgentButton, src: cross, onClick: () => this.unassignAgent(agent._id) }))))))));
    }
}
export default withTranslation()(withStore(observer(DepartmentForm)));
