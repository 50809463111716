var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Button, Divider, Radio, Tooltip, Typography } from 'antd';
import classnames from 'classnames';
import { Trans } from 'react-i18next';
import { DownOutlined, LoadingOutlined } from '@ant-design/icons';
import { AfterResolveChatAction, CHAT_STATUS } from '../../../../../constants';
import AssignToModal from '../../../modals/AssignToModal';
import styles from './ResolveChatButton.module.scss';
import { useStore } from '../../../../../store/rootStore';
import { AiOutlineSetting } from 'react-icons/ai';
import useResolveChat from '../../../../../helpers/hooks/useResolveChat';
import { getDirection } from '../../../../../helpers';
const { Text } = Typography;
class ChatStatusButtons extends Component {
    constructor(props) {
        super(props);
        Object.defineProperty(this, "closingMessageButtonRef", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "closingMessageOptionsRef", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "containerRef", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "assignRef", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "handleClickOutside", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                var _a, _b, _c, _d, _e, _f, _g;
                if (this.closingMessageButtonRef &&
                    !((_b = (_a = this.closingMessageButtonRef) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.contains(e.target)) &&
                    this.closingMessageOptionsRef &&
                    !((_d = (_c = this.closingMessageOptionsRef) === null || _c === void 0 ? void 0 : _c.current) === null || _d === void 0 ? void 0 : _d.contains(e.target))) {
                    this.setState({ openClosingMessageOptions: false });
                }
                if (this.containerRef && !((_f = (_e = this.containerRef) === null || _e === void 0 ? void 0 : _e.current) === null || _f === void 0 ? void 0 : _f.contains(e.target)) && this.props.onComplete) {
                    if (!((_g = document.querySelector('.moreButtonMobile')) === null || _g === void 0 ? void 0 : _g.contains(e.target))) {
                        this.props.onComplete();
                    }
                }
            }
        });
        Object.defineProperty(this, "onHover", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (hovered) => {
                this.setState({ hovered });
            }
        });
        Object.defineProperty(this, "handleOnClick", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                const { resolveChat } = this.props.resolveChatProps;
                const { chatId } = this.state;
                try {
                    this.setState({ loading: true, openClosingMessageOptions: false });
                    yield resolveChat();
                    if (this.props.onComplete) {
                        this.props.onComplete();
                    }
                }
                catch (err) {
                    console.log('Error: resolving chat failed', chatId, err);
                }
                finally {
                    this.setState({ loading: false });
                }
            })
        });
        Object.defineProperty(this, "toggleAssignModal", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (newState) => {
                if (newState === false && this.props.onComplete) {
                    this.props.onComplete();
                }
                this.setState({ isAssignModalVisible: newState });
            }
        });
        Object.defineProperty(this, "isAssignToDisabled", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { chat: activeChat, chatAccess, filePreview } = this.props.store.activeChatStore;
                return this.state.loading || !activeChat || !(chatAccess === null || chatAccess === void 0 ? void 0 : chatAccess.manage) || filePreview;
            }
        });
        Object.defineProperty(this, "isMarkSolvedDisabled", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { chat: activeChat, chatAccess, filePreview } = this.props.store.activeChatStore;
                return this.state.loading || !activeChat || activeChat.status === CHAT_STATUS.RESOLVED || !(chatAccess === null || chatAccess === void 0 ? void 0 : chatAccess.manage) || filePreview;
            }
        });
        Object.defineProperty(this, "openBotNodeSelectModal", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                const { toggleSelectBotModal } = this.props.resolveChatProps;
                e.stopPropagation();
                e.preventDefault();
                if (this.props.store.activeChatStore.sessionIsOver)
                    return;
                toggleSelectBotModal();
            }
        });
        Object.defineProperty(this, "openClosingMessageOptions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                (_a = this.closingMessageButtonRef.current) === null || _a === void 0 ? void 0 : _a.blur();
                this.setState({ openClosingMessageOptions: !this.state.openClosingMessageOptions });
            }
        });
        Object.defineProperty(this, "renderResolveButtonContents", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { loading } = this.state, { sessionIsOver, theme } = this.props.store.activeChatStore;
                const { afterResolveAction, selectedBotNode } = this.props.resolveChatProps;
                if (loading) {
                    return React.createElement(LoadingOutlined, null);
                }
                if (!sessionIsOver && afterResolveAction !== AfterResolveChatAction.NONE) {
                    return (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: classnames(styles.buttonBoldText, {
                                [styles.buttonGreyText]: this.isMarkSolvedDisabled(),
                            }) },
                            React.createElement(Trans, { i18nKey: "markAsSolved" })),
                        React.createElement("div", { className: classnames(styles.buttonText, {
                                [styles.buttonGreyText]: this.isMarkSolvedDisabled(),
                            }) },
                            React.createElement(Trans, Object.assign({ i18nKey: afterResolveAction === AfterResolveChatAction.SEND_CLOSING_MESSAGE
                                    ? 'sendClosingMessage'
                                    : (getDirection() === 'rtl' ? 'runBotRTL' : 'runBot') }, (afterResolveAction === AfterResolveChatAction.RUN_BOT) && {
                                values: { botNode: selectedBotNode }
                            })))));
                }
                return React.createElement(Trans, { i18nKey: "markAsSolved" });
            }
        });
        const { chat } = this.props.store.activeChatStore;
        this.state = {
            loading: false,
            isAssignModalVisible: false,
            openClosingMessageOptions: false,
            chatId: chat ? chat._id : null,
            hovered: false,
        };
        this.closingMessageButtonRef = React.createRef();
        this.closingMessageOptionsRef = React.createRef();
        this.containerRef = React.createRef();
    }
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    render() {
        const { visible, store, resolveChatProps } = this.props;
        const { chatAccess, sessionIsOver, theme } = store.activeChatStore;
        const { isAssignModalVisible, openClosingMessageOptions, } = this.state;
        const { onAfterResolveActionChange, afterResolveAction } = resolveChatProps;
        return (React.createElement("div", { ref: this.containerRef, style: { display: !visible ? 'none' : 'flex' }, className: classnames(styles.resolveChatContainer, {
                [styles.hiddenResolveChatContainer]: isAssignModalVisible,
            }) },
            React.createElement(Tooltip, { title: !(chatAccess === null || chatAccess === void 0 ? void 0 : chatAccess.manage) ? React.createElement(Trans, { i18nKey: "noChatManagePermission" }) : null, placement: "top" },
                React.createElement("div", { className: styles.resolveChatButtonsBlock },
                    React.createElement(Button, { disabled: this.isAssignToDisabled(), ref: (assignRef) => {
                            this.assignRef = assignRef;
                        }, className: classnames('white-button', styles.resolveButton, styles.assignToButton, {
                            'white-button-disabled': this.isAssignToDisabled(),
                        }), onClick: () => {
                            var _a;
                            (_a = this.assignRef) === null || _a === void 0 ? void 0 : _a.blur();
                            this.toggleAssignModal(true);
                        } },
                        React.createElement(Trans, { i18nKey: "assignToDots" })),
                    React.createElement("div", { className: styles.resolveButtonWrapper, onMouseEnter: () => this.onHover(true), onMouseLeave: () => this.onHover(false) },
                        React.createElement(Button, { disabled: this.isMarkSolvedDisabled(), className: styles.resolveButton, onClick: this.handleOnClick }, this.renderResolveButtonContents()),
                        React.createElement(Button, { ref: this.closingMessageButtonRef, disabled: this.isMarkSolvedDisabled(), className: classnames('big-green-button', styles.secondaryOptionsButton), onClick: this.openClosingMessageOptions },
                            React.createElement(DownOutlined, { className: classnames({
                                    [styles.arrowOpen]: openClosingMessageOptions,
                                }) }))))),
            openClosingMessageOptions && (React.createElement("div", { ref: this.closingMessageOptionsRef, className: styles.sendMessageContainer },
                React.createElement(Text, { className: styles.radioHeader },
                    React.createElement(Trans, { i18nKey: "afterClickOnButton" })),
                React.createElement(Divider, { className: styles.radioDivider }),
                React.createElement(Radio.Group, { onChange: (e) => {
                        onAfterResolveActionChange(e.target.value);
                        this.setState({ openClosingMessageOptions: false });
                    }, value: !sessionIsOver
                        ? afterResolveAction
                        : AfterResolveChatAction.NONE },
                    React.createElement(Radio, { className: styles.radioOption, value: AfterResolveChatAction.NONE },
                        React.createElement(Trans, { i18nKey: "onlyResolveChat" })),
                    React.createElement(Divider, { className: styles.radioDivider }),
                    React.createElement(Radio, { className: styles.radioOption, disabled: sessionIsOver, value: AfterResolveChatAction.SEND_CLOSING_MESSAGE },
                        React.createElement(Text, { className: classnames(styles.radioOptionText, {
                                [styles.disabled]: sessionIsOver,
                            }) },
                            React.createElement(Trans, { i18nKey: "resolveChatAndSendClosingMessage" }))),
                    React.createElement(Divider, { className: styles.radioDivider }),
                    React.createElement(Radio, { className: styles.radioOption, disabled: sessionIsOver, value: AfterResolveChatAction.RUN_BOT },
                        React.createElement(Text, { className: classnames(styles.radioOptionText, {
                                [styles.disabled]: sessionIsOver,
                            }) },
                            React.createElement(Trans, { i18nKey: "resolveChatAndRunBot" }),
                            React.createElement(Tooltip, { title: !sessionIsOver ? React.createElement(Trans, { i18nKey: "selectBotNode" }) : '', placement: "topLeft" },
                                React.createElement(AiOutlineSetting, { onClick: this.openBotNodeSelectModal }))))))),
            React.createElement(AssignToModal, { isAssignModalVisible: isAssignModalVisible, toggleModal: this.toggleAssignModal, assignFunction: store.chatsStore.assignChat }),
            resolveChatProps.resolveChatModal));
    }
}
export default observer((props) => {
    const store = useStore();
    const resolveChatProps = __rest(useResolveChat(), []);
    return React.createElement(ChatStatusButtons, Object.assign({}, props, { store, resolveChatProps }));
});
