var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { merge } from 'lodash';
import { Tabs } from 'antd';
import FileSaver from 'file-saver';
import { MEDIA_TYPE } from '@whatsper/texterchat-common';
import moment from 'moment';
import { useStore } from '../../../../../store/rootStore';
import LazyLoadList from '../../../../Base/Utils/LazyLoadList';
import MediaItem from './components/MediaItem';
import MediaHeader from './components/MediaHeader';
import FilesMediaLinksHeader from './components/FilesMediaLinksHeader';
import s from './FilesMediaLinks.module.scss';
export const mediaFilesTabs = {
    media: {
        title: 'Media',
        key: 'media',
        mediaTypes: [MEDIA_TYPE.IMAGE, MEDIA_TYPE.VIDEO],
        showCheckbox: true,
    },
    doc: {
        title: 'Docs',
        key: 'doc',
        mediaTypes: [MEDIA_TYPE.DOC],
        showCheckbox: true,
    },
};
const FilesMediaLinks = ({ chat, onClose }) => {
    const { activeChatStore, chatsStore, files } = useStore();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const { theme } = activeChatStore;
    const [downloading, setDownloading] = useState(false);
    const [selectedTab, setSelectedTab] = useState('media');
    const [mediaFilesLists, setMediaFilesLists] = useState(Object.keys(mediaFilesTabs).reduce((acc, key) => {
        acc[key] = {
            data: {},
            hasMore: false,
        };
        return acc;
    }, {}));
    useEffect(() => {
        onLoadData();
    }, [selectedTab]);
    useEffect(() => {
        setSelectedFiles([]);
        setSelectedTab('media');
    }, [chat]);
    const onBack = () => {
        if (onClose) {
            onClose();
        }
    };
    const onDownload = () => __awaiter(void 0, void 0, void 0, function* () {
        if (selectedFiles.length) {
            setDownloading(true);
            yield files.downloadFilesArchive(selectedFiles);
            setSelectedFiles([]);
            setDownloading(false);
        }
    });
    const onLoadData = (loadMore = false) => __awaiter(void 0, void 0, void 0, function* () {
        const mediaGroup = mediaFilesTabs[selectedTab].key;
        setLoading(true);
        const data = yield chatsStore
            .getMediaFilesList({
            chatId: chat._id,
            mediaTypes: mediaFilesTabs[selectedTab].mediaTypes,
        })
            .catch(() => console.error('Failed to load media files list'))
            .finally(() => setLoading(false));
        if (data) {
            setMediaFilesLists(Object.assign(Object.assign({}, mediaFilesLists), { [mediaGroup]: {
                    data: loadMore ? merge(mediaFilesLists[mediaGroup].data, data.media) : data.media,
                    hasMore: data.hasMore,
                } }));
        }
    });
    const onDownloadLegacyImage = (url, filename) => {
        FileSaver.saveAs(url, filename);
    };
    const onFileSelect = (fileId) => {
        if (selectedFiles.includes(fileId)) {
            setSelectedFiles(selectedFiles.filter((id) => id !== fileId));
        }
        else {
            setSelectedFiles([...selectedFiles, fileId]);
        }
    };
    const onMultipleFileSelect = (fileIds) => {
        if (fileIds.every((id) => selectedFiles.includes(id))) {
            setSelectedFiles(selectedFiles.filter((id) => !fileIds.includes(id)));
        }
        else {
            setSelectedFiles([...selectedFiles, ...fileIds]);
        }
    };
    const sortAndFilterFiles = (mediaGroup, type) => {
        var _a;
        /* sort by date and filter by Tab type */
        return Object.entries((_a = mediaFilesLists[mediaGroup]) === null || _a === void 0 ? void 0 : _a.data)
            .sort((a, b) => moment(b[0]).diff(moment(a[0])))
            .filter(([_, mediaArray]) => mediaArray.some(({ mediaType }) => type.includes(mediaType)));
    };
    return (React.createElement("div", { className: s.wrapper },
        React.createElement(FilesMediaLinksHeader, { onBack: onBack, onDownload: onDownload, loading: downloading, numberOfSelectedFiles: selectedFiles.length }),
        React.createElement("div", { className: s.body },
            React.createElement(Tabs, { className: s.tabs, activeKey: selectedTab, onChange: (key) => setSelectedTab(key) }, Object.values(mediaFilesTabs).map(({ title, key, mediaTypes, showCheckbox }) => {
                var _a;
                return (React.createElement(Tabs.TabPane, { tab: title, key: key },
                    React.createElement(LazyLoadList, { loadingNext: loading, hasNext: (_a = mediaFilesLists[key]) === null || _a === void 0 ? void 0 : _a.hasMore, onLoadNext: () => onLoadData(true) }, sortAndFilterFiles(key, mediaTypes).map(([date, mediaArray], i) => (React.createElement(React.Fragment, { key: i },
                        React.createElement(MediaHeader, { date: date, mediaArray: mediaArray, onMultipleFileSelect: onMultipleFileSelect, selectedFiles: selectedFiles }),
                        React.createElement("div", { className: s[`${key}Wrapper`] }, mediaArray.map((mediaItem, i) => {
                            /* Loop over {mediaArray}, and gather them under the {date} header */
                            if (!mediaItem.fileId && !mediaItem.url)
                                return null;
                            return (React.createElement(MediaItem, { mediaGroup: key, mediaItem: mediaItem, onFileSelect: onFileSelect, onDownloadLegacyImage: onDownloadLegacyImage, selectedFiles: selectedFiles, shareFile: files.shareFile, showCheckbox: showCheckbox, color: theme.color, key: i }));
                        }))))))));
            })))));
};
export default observer(FilesMediaLinks);
