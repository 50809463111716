import React from 'react';
import phoneCallSvg from '../../../../../../assets/icons/noGroupIcons/phoneCallSvg.svg';
import externalLink from '../../../../../../assets/icons/arrows/export/externalLink.svg';
import './CallToActionButtonsBlock.css';
const CallToActionButtonsBlock = ({ messageBlock, withIcon = true }) => (React.createElement("div", null,
    React.createElement("div", { className: "call-to-action-buttons-block" }, messageBlock.buttons.map((button, i) => (React.createElement("div", { key: i },
        React.createElement("button", { disabled: true, key: i, className: "call-to-action-button" },
            withIcon && (React.createElement("img", { className: "call-to-action-button-image", src: button.type === 'PHONE_NUMBER' ? phoneCallSvg : externalLink })),
            React.createElement("p", { className: "call-to-action-button-text" }, button.text))))))));
export default CallToActionButtonsBlock;
