import React from 'react';
import classNames from 'classnames';
import { MESSAGE_DIRECTION } from '@whatsper/texterchat-common';
import { BsHeadphones } from 'react-icons/bs';
import { getFilePreviewUrl } from '../../../../../../store/FilesStore';
import styles from './AudioPostback.module.scss';
const AudioPostback = ({ context, name, type, incoming, invertChatColor }) => {
    let { fileId, url } = context.media[0];
    if (fileId) {
        url = getFilePreviewUrl(fileId.toString());
    }
    return (React.createElement("div", { className: classNames(styles.main, {
            [styles.reply]: type === 'reply',
            [styles.invertColors]: !incoming && invertChatColor && type === 'message',
        }) },
        React.createElement("div", { className: styles.info },
            React.createElement("p", { className: classNames(styles.name, {
                    [styles.incoming]: incoming,
                }) }, context.direction === MESSAGE_DIRECTION.IN ? name : 'You'),
            React.createElement("div", { className: classNames(styles.text, {
                    [styles.reply]: type === 'reply',
                    [styles.invertColors]: !incoming && type === 'message',
                }) },
                React.createElement(BsHeadphones, null),
                React.createElement("span", null, "Audio")))));
};
export default AudioPostback;
