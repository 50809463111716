var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { Auth } from '@whatsper/texterchat-common';
import { Trans } from 'react-i18next';
import { Button } from 'antd';
import { useStore } from '../../../../../store/rootStore';
import LoadingIcon from '../../../../Chat/common/LoadingIcon';
import FacebookSSOButton from './providerButtons/FacebookSSOButton';
import GoogleSSOButton from './providerButtons/GoogleSSOButton';
import MicrosoftSSOButton from './providerButtons/MicrosoftSSOButton';
import s from '../../../Login.module.scss';
var Binding = Auth.Methods.SAML.Binding;
const SAML = () => {
    const [samlInfo, setSAMLInfo] = useState(null);
    const [loading, setLoading] = useState(false);
    const { loginStore } = useStore();
    const fetchSAMLInfo = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            const info = yield loginStore.getSAMLInfo();
            setSAMLInfo(info);
        }
        catch (e) {
            console.error('Error getting SAML info', e);
        }
        finally {
            setLoading(false);
        }
    });
    const getButtonByTitle = (samlInfo) => {
        const onClick = () => initSAMLLogin(samlInfo);
        switch (samlInfo.idpInfo.title) {
            case 'Facebook':
                return React.createElement(FacebookSSOButton, { onClick: onClick });
            case 'Google':
                return React.createElement(GoogleSSOButton, { onClick: onClick });
            case 'Microsoft':
                return React.createElement(MicrosoftSSOButton, { onClick: onClick });
            default:
                return (React.createElement(Button, { type: "primary", onClick: onClick },
                    React.createElement(Trans, { i18nKey: "loginSAMLMethod" })));
        }
    };
    const initSAMLLogin = (samlInfo) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            const loginBinding = samlInfo.supportedBindings.login.includes(Binding.redirect)
                ? Binding.redirect
                : Binding.post;
            const loginResult = yield loginStore.initSAMLogin(loginBinding);
            if (loginResult) {
                // Redirect user to SAML login page
                switch (loginResult.binding) {
                    case Binding.redirect:
                        window.location.href = loginResult.url;
                        break;
                    case Binding.post:
                        {
                            const form = document.createElement('form');
                            form.method = 'POST';
                            form.action = loginResult.url;
                            form.target = '_blank';
                            const typeInput = document.createElement('input');
                            typeInput.name = loginResult.type;
                            typeInput.value = loginResult.context;
                            form.appendChild(typeInput);
                            if (loginResult.relayState) {
                                const relayStateInput = document.createElement('input');
                                relayStateInput.name = 'RelayState';
                                relayStateInput.value = loginResult.relayState;
                                form.appendChild(relayStateInput);
                            }
                            document.body.appendChild(form);
                            form.submit();
                            document.body.removeChild(form);
                        }
                        break;
                }
            }
        }
        catch (e) {
            console.error('Error initializing SAML login', e);
        }
        finally {
            setLoading(false);
        }
    });
    useEffect(() => {
        fetchSAMLInfo();
    }, []);
    if (loading || !samlInfo) {
        return (React.createElement("div", { className: s.pageWrapper },
            React.createElement(LoadingIcon, null)));
    }
    return React.createElement("div", { className: s.pageWrapper }, getButtonByTitle(samlInfo));
};
export default SAML;
