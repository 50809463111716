import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { Checkbox, Dropdown, Tooltip } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import cross from '../../../assets/messageStatuses/Cross.png';
import styles from './ConversationSearch.module.scss';
import { withStore } from '../../../store/rootStore';
import { observer } from 'mobx-react';
import { MyInput } from '../../Base/Form';
import classNames from 'classnames';
class ConversationSearch extends Component {
    constructor(props) {
        super(props);
        Object.defineProperty(this, "inputRef", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "onChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (inputValue) => {
                this.setState({ inputValue }, () => this.onSearch());
            }
        });
        Object.defineProperty(this, "cleanSearchInput", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({
                    inputValue: '',
                    inMessages: this.props.inMessages === 'always',
                }, () => this.onSearch());
            }
        });
        Object.defineProperty(this, "onSearch", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { inputValue, inMessages } = this.state, { onSearch, minQuery = 3 } = this.props;
                if (inputValue.length >= minQuery) {
                    onSearch(inputValue, inMessages);
                }
                else {
                    onSearch(null, inMessages);
                }
            }
        });
        this.state = {
            inputValue: '',
            inMessages: props.inMessages === 'always',
            showSearchBar: !props.isMobile,
        };
        this.inputRef = React.createRef();
    }
    componentWillUnmount() {
        this.props.onSearch('', this.state.inMessages);
    }
    componentDidUpdate(prevProps, prevState) {
        var _a;
        if (this.state.showSearchBar && !prevState.showSearchBar) {
            (_a = this.inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
        if (prevState.showSearchBar && !this.state.showSearchBar) {
            this.cleanSearchInput();
        }
    }
    render() {
        const { t, minQuery = 3, dropdownMenu, dropdownIcon: Icon, loading, channelSelect, inMessages, filtersApplied, isMobile, } = this.props, { inputValue, showSearchBar } = this.state;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: classNames(styles.conversationSearch, {
                    [styles.showSearchBar]: showSearchBar,
                }) },
                React.createElement("div", { className: styles.contentWrapper },
                    isMobile && channelSelect,
                    React.createElement("div", { className: styles.actionsWrapper },
                        isMobile && (React.createElement("div", { className: classNames(styles.icon, {
                                [styles.active]: showSearchBar,
                            }), onClick: () => this.setState({ showSearchBar: !showSearchBar }) },
                            React.createElement(SearchOutlined, null))),
                        React.createElement(Dropdown, { trigger: ['click'], placement: "bottomRight", menu: dropdownMenu, overlayStyle: { width: '100%', left: 0 }, getPopupContainer: (triggerNode) => triggerNode.parentElement },
                            React.createElement("div", { className: classNames(styles.icon, styles.filterIcon) },
                                React.createElement(Icon, null),
                                isMobile && filtersApplied > 0 && React.createElement("div", { className: styles.filtersApplied }))))),
                showSearchBar && (React.createElement(Tooltip, { title: React.createElement("span", null,
                        React.createElement(Trans, { i18nKey: "pleaseTypeAtLeast" }),
                        " ",
                        React.createElement("b", null, minQuery - inputValue.length),
                        ' ',
                        React.createElement(Trans, { i18nKey: "moreSymbolsToStartSearch" })), overlayClassName: inputValue.length >= minQuery ? styles.hidden : undefined, trigger: "focus" },
                    React.createElement("div", { className: styles.searchWrapper },
                        React.createElement(MyInput, { forwardRef: this.inputRef, type: "search", size: "large", placeholder: t('searchChat'), value: this.state.inputValue, className: styles.searchInput, onChangeHandler: this.onChange, loading: loading && inputValue.length >= minQuery, bordered: false, allowClear: {
                                clearIcon: (React.createElement("img", { className: styles.cleanSearchInput, src: cross, onClick: this.cleanSearchInput })),
                            } }),
                        inMessages === 'available' && inputValue.length > 0 && (React.createElement("div", { className: styles.conversationSearchFulltext },
                            React.createElement(Checkbox, { checked: this.state.inMessages, onChange: (e) => this.setState({ inMessages: e.target.checked }, () => this.onSearch()) },
                                React.createElement(Trans, { i18nKey: "searchInMessages" }))))))))));
    }
}
Object.defineProperty(ConversationSearch, "defaultProps", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: {
        minQuery: 3,
        inMessages: 'always',
    }
});
export default withTranslation()(withStore(observer(ConversationSearch)));
