import React from 'react';
import LoadingIcon from '../../../../../Chat/common/LoadingIcon';
import classNames from 'classnames';
import { AiOutlineDownload } from 'react-icons/ai';
import { Tooltip } from 'antd';
import { Trans } from 'react-i18next';
import { IoMdArrowBack } from 'react-icons/io';
import s from '../FilesMediaLinks.module.scss';
const FilesMediaLinksHeader = ({ onBack, loading, numberOfSelectedFiles, onDownload }) => {
    return (React.createElement("div", { className: s.header },
        React.createElement("div", { className: s.headerContentWrapper },
            React.createElement("div", { className: s.iconWrapper, onClick: onBack },
                React.createElement(IoMdArrowBack, { className: s.icon })),
            numberOfSelectedFiles > 0 && React.createElement("div", { className: s.selectedFilesLabel },
                "Selected: ",
                numberOfSelectedFiles)),
        loading ? (React.createElement(LoadingIcon, { additionalStyles: classNames(s['loading-icon'], s.downloadLoader) })) : numberOfSelectedFiles > 0 ? (React.createElement(Tooltip, { title: React.createElement(Trans, { i18nKey: "downloadMedia" }), placement: "left" },
            React.createElement("div", { className: s.iconWrapper, onClick: onDownload },
                React.createElement(AiOutlineDownload, { className: s.icon })))) : null));
};
export default FilesMediaLinksHeader;
