import classNames from 'classnames';
import React from 'react';
import { getDirection } from '../../../../helpers';
import s from './ButtonsMessage.module.scss';
const ButtonsMessage = ({ text, buttons, timestamp, status, }) => {
    return (React.createElement("div", { className: s.container },
        React.createElement("div", { className: s.buttonsWrapper },
            React.createElement("p", { dir: "auto", className: classNames(s.buttonsBody, { [s.rtl]: getDirection() === 'rtl' }) }, text),
            React.createElement("div", { className: classNames(s.receiver, s.timestampContainer) },
                React.createElement("p", { className: s.timestampUser }, timestamp),
                status)),
        buttons.map(({ title, payload }, i) => (React.createElement("button", { key: i, className: s.button, disabled: true },
            React.createElement("p", { dir: "auto", className: classNames(s.text) }, title || payload))))));
};
export default ButtonsMessage;
