import React from 'react';
const Trashbin = ({ color = '#000000', width = 24, height = 24, className }) => (React.createElement("svg", { width: width, height: height, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", className: className },
    React.createElement("rect", { x: "0.5", y: "0.5", width: "23", height: "23", rx: "11.5", fill: "white" }),
    React.createElement("rect", { x: "0.5", y: "0.5", width: "23", height: "23", rx: "11.5", stroke: color }),
    React.createElement("path", { d: "M9.33301 7.66667V6.33333C9.33301 5.66667 9.99967 5 10.6663 5H13.333C13.9997 5 14.6663 5.66667 14.6663 6.33333V7.66667", stroke: color, strokeWidth: "1.4", strokeLinecap: "round", strokeLinejoin: "round" }),
    React.createElement("path", { d: "M6 7.66602H18", stroke: color, strokeWidth: "1.4", strokeLinecap: "round", strokeLinejoin: "round" }),
    React.createElement("path", { d: "M16.6663 7.66602V16.9993C16.6663 17.666 15.9997 18.3327 15.333 18.3327H8.66634C7.99967 18.3327 7.33301 17.666 7.33301 16.9993V7.66602", stroke: color, strokeWidth: "1.4", strokeLinecap: "round", strokeLinejoin: "round" }),
    React.createElement("path", { d: "M10.667 11.0273V14.8607", stroke: color, strokeWidth: "1.4", strokeLinecap: "round", strokeLinejoin: "round" }),
    React.createElement("path", { d: "M13.333 11.0273V14.8607", stroke: color, strokeWidth: "1.4", strokeLinecap: "round", strokeLinejoin: "round" })));
export default Trashbin;
