import * as React from 'react';
import escapeStringRegexp from 'escape-string-regexp';
import './HighlightedText.css';
export default class HighlightedText extends React.Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "parseQuotesIfAny", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (highlighted) => {
                /* remove quotes only if they are first and last char in a string */
                if (typeof highlighted !== 'string') {
                }
                return highlighted.replace(/^"(.*)"$/, '$1');
            }
        });
        Object.defineProperty(this, "render", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const patterns = [];
                if (typeof this.props.highlighted === 'string') {
                    patterns.push(escapeStringRegexp(this.parseQuotesIfAny(this.props.highlighted)));
                }
                else {
                    this.props.highlighted.forEach((item) => {
                        patterns.push(escapeStringRegexp(this.parseQuotesIfAny(item)));
                    });
                }
                const escapedPatterns = patterns.map((pattern) => escapeStringRegexp(pattern).replace(/\\ /g, '\\s+') // Replace escaped space with \s+ to match any whitespace
                );
                const regexp = new RegExp(escapedPatterns.join('|'), 'gi');
                const { text = '' } = this.props;
                let parts = [], match, prevEnd = 0, i = 0;
                while ((match = regexp.exec(text)) !== null && (match === null || match === void 0 ? void 0 : match[0]) !== '') {
                    if (match.index > prevEnd) {
                        parts.push(text.slice(prevEnd, match.index));
                        i++;
                    }
                    parts.push(React.createElement("span", { key: match[0] + '-' + i, className: this.props.className }, match[0]));
                    i++;
                    prevEnd = regexp.lastIndex;
                }
                if (prevEnd < text.length) {
                    parts.push(text.slice(prevEnd, text.length));
                }
                return parts;
            }
        });
    }
}
Object.defineProperty(HighlightedText, "defaultProps", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: {
        className: 'highlighted',
    }
});
