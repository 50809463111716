import React, { useState, useEffect, useRef } from "react";
import { Trans } from "react-i18next";
import Icon from "@ant-design/icons";
import { Typography } from "antd";
import classnames from "classnames";
import { ReactComponent as UploadCloud } from "../../../assets/icons/download/uploadCloud.svg";
import styles from "./DragAndDrop.module.scss";
import { withStore } from "../../../store/rootStore";
import { observer } from "mobx-react";
const { Text } = Typography;
const DragAndDrop = ({ handleDrop, active, children, store, isReplyOpen }) => {
    const [dragging, setDragging] = useState(0);
    const dropRef = useRef(null);
    const { filePreview, theme } = store.activeChatStore;
    useEffect(() => {
        window.addEventListener("dragover", (e) => handleDrag(e), false);
        return window.removeEventListener("dragover", handleDrag);
    }, []);
    useEffect(() => {
        window.addEventListener("drop", (e) => handleDrag(e), false);
        return window.removeEventListener("drop", handleDrag);
    }, []);
    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };
    const handleDragIn = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (active && e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setDragging(dragging + 1);
        }
    };
    const handleDragOut = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (active) {
            setDragging(dragging - 1);
        }
    };
    const handleDropping = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(0);
        if (active && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            handleDrop(e.dataTransfer.files);
            e.dataTransfer.clearData();
        }
    };
    return (React.createElement("div", { className: classnames(styles.container, {
            [styles.filePreview]: filePreview,
            [styles.replyOpen]: isReplyOpen,
        }), ref: dropRef, onDragEnter: handleDragIn, onDragOver: handleDrag, onDrop: handleDropping, onDragLeave: handleDragOut },
        dragging ? (React.createElement("div", { className: styles.box, style: { borderColor: theme.color } },
            React.createElement("div", { className: styles.inner },
                React.createElement(Icon, { className: styles.icon, component: UploadCloud }),
                React.createElement(Text, { className: styles.text },
                    React.createElement(Trans, { i18nKey: "dragHere" }))))) : null,
        children));
};
export default withStore(observer(DragAndDrop));
