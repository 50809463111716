var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { action, observable, runInAction, computed, makeObservable } from 'mobx';
import { v4 as uuid } from 'uuid';
import { MESSAGE_STATUS, } from '@whatsper/texterchat-common';
import { omit, throttle } from 'lodash';
import { API_PATH, SEARCH_THROTTLE, SEARCH_MIN_QUERY } from '../../constants';
import { getAxios } from '../../backend';
import { getFileUrl, STORAGE_AGENT_DIR } from '../FilesStore';
import { MESSAGE_DIRECTION, MessageContentsType } from '@whatsper/texterchat-common';
export default class MessagesStore {
    constructor(rootStore) {
        Object.defineProperty(this, "rootStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "search", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "searchLoading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: MessagesSearchLoading.NONE
        });
        Object.defineProperty(this, "_searchResults", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "loadSearchResultsDebounced", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: throttle(this.loadSearchResults, SEARCH_THROTTLE, { trailing: true })
        });
        makeObservable(this);
        this.rootStore = rootStore;
    }
    get searchResults() {
        return this._searchResults ? this._searchResults.messages : [];
    }
    get searchHasMore() {
        return !!(this._searchResults && this._searchResults.totalHits > this._searchResults.messages.length);
    }
    setSearch(search) {
        const changed = this.search !== search;
        this.search = search;
        if (changed) {
            this.loadSearchResultsDebounced(true);
        }
    }
    _getSearchResults(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return getAxios()
                .then((axios) => {
                return axios.get(`${API_PATH.MESSAGES}/search`, { params });
            })
                .then(({ data }) => {
                return data;
            });
        });
    }
    loadSearchResults(resetPagination = false) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!this.search || this.search.length < SEARCH_MIN_QUERY) {
                    runInAction(() => this._searchResults = null);
                    return;
                }
                const results = this._searchResults;
                let params = {
                    limit: '50',
                    search: this.search,
                };
                if (!resetPagination) {
                    if (results && results.totalHits && results.messages.length >= results.totalHits) {
                        console.warn('Skipped messages search more loading because all hits loaded already');
                        return;
                    }
                    if (results && results.messages.length) {
                        params.skip = `${results.messages.length}`;
                    }
                }
                runInAction(() => {
                    this.searchLoading = !params.skip ? MessagesSearchLoading.ALL : MessagesSearchLoading.MORE;
                });
                const data = yield this._getSearchResults(params);
                runInAction(() => {
                    if (!this._searchResults || resetPagination) {
                        this._searchResults = observable(data);
                    }
                    else {
                        this._searchResults.messages.push(...data.messages);
                    }
                    this.searchLoading = MessagesSearchLoading.NONE;
                });
            }
            catch (error) {
                console.error('Error on messages search', error);
                runInAction(() => this.searchLoading = MessagesSearchLoading.NONE);
            }
        });
    }
    fetchMessages(chatId, query, limit = 50) {
        return __awaiter(this, void 0, void 0, function* () {
            return getAxios()
                .then((axios) => {
                return axios.get(API_PATH.MESSAGES, {
                    params: Object.assign(Object.assign({}, query), { chatId,
                        limit }),
                });
            })
                .then(({ data }) => {
                return data;
            });
        });
    }
    /**
     * Send simple text message.
     *
     * @todo Rewrite method to always accept files IDs and not files objects (uplod before calling this method)
     *
     * @param file File object to upload or uploaded file ID
     * @param chatId Chat ID
     * @param mediaType Attached media type
     * @param fileCaption File caption. If not provided and provided file object then used file name, empty string otherwise
     *
     * @returns Array where first item is temporary message and second - promise resolving to sent message updated document
     */
    sendMediaMessage(chatId, files, fileCaption, messageQuotedId, special) {
        var _a;
        const timestamp = Math.floor(Date.now() / 1000);
        const { uploadFiles } = this.rootStore.files;
        const id = (_a = this.rootStore.loginStore.userDetails) === null || _a === void 0 ? void 0 : _a._id;
        const mediaFormated = files.map(({ file, type, name, fileId }) => {
            const previewUrl = fileId ? getFileUrl(fileId) : file ? URL.createObjectURL(file) : '';
            return Object.assign(Object.assign({ mediaType: type, url: previewUrl }, (name && { filename: name })), (fileCaption && { caption: fileCaption }));
        });
        const uploadFilesAndReturnIds = () => __awaiter(this, void 0, void 0, function* () {
            const filesToUpload = [], ids = [];
            files.forEach(({ file, fileId }) => {
                if (fileId) {
                    ids.push({ fileId });
                    return;
                }
                if (file) {
                    filesToUpload.push(file);
                }
            });
            const uploadedFiles = yield uploadFiles(filesToUpload, `${STORAGE_AGENT_DIR}/${id}/${timestamp}`);
            if (uploadedFiles) {
                ids.push(...uploadedFiles);
            }
            return ids;
        });
        const tempMessage = Object.assign(Object.assign({}, this.tempMessage(chatId)), { type: MessageContentsType.media, media: mediaFormated });
        if (messageQuotedId) {
            tempMessage.context = messageQuotedId;
        }
        if (special) {
            tempMessage.special = special;
        }
        const message = (uploadedFiles, messageQuotedId) => {
            var _a;
            return (Object.assign(Object.assign({ type: MessageContentsType.media, tmpSendId: (_a = tempMessage.tmpSendId) === null || _a === void 0 ? void 0 : _a.toString(), 
                // @ts-expect-error
                media: tempMessage.media.map((media, i) => {
                    var _a;
                    return (Object.assign(Object.assign({}, omit(media, 'url')), { fileId: (_a = uploadedFiles[i]) === null || _a === void 0 ? void 0 : _a.fileId }));
                }) }, (messageQuotedId && { context: messageQuotedId })), (special && { special })));
        };
        return [
            tempMessage,
            uploadFilesAndReturnIds().then((uploadedFiles) => {
                if (uploadedFiles) {
                    return this.sendMessage(chatId, [message(uploadedFiles, messageQuotedId)]);
                }
                else {
                    throw new Error('Error uploading files');
                }
            }),
        ];
    }
    /**
     * Send simple text message.
     * @returns Array where first item is temporary message and second - promise resolving to sent message updated document
     */
    sendTextMessage(chatId, text, messageQuotedId, special) {
        const tempMessage = Object.assign(Object.assign({}, this.tempMessage(chatId)), { type: MessageContentsType.text, text });
        const message = {
            type: MessageContentsType.text,
            tmpSendId: tempMessage.tmpSendId.toString(),
            // @ts-expect-error
            text,
        };
        if (messageQuotedId) {
            message.context = messageQuotedId;
            tempMessage.context = messageQuotedId;
        }
        if (special) {
            message.special = special;
            tempMessage.special = special;
        }
        return [tempMessage, this.sendMessage(chatId, [message])];
    }
    sendContactsMessage(chatId, contacts, special) {
        const tempMessage = Object.assign(Object.assign({}, this.tempMessage(chatId)), { contacts });
        const message = {
            type: MessageContentsType.contacts,
            tmpSendId: tempMessage.tmpSendId.toString(),
            // @ts-expect-error
            contacts,
        };
        if (special) {
            tempMessage.special = special;
            message.special = special;
        }
        return [tempMessage, this.sendMessage(chatId, [message])];
    }
    sendLocationMessage(chatId, location, special) {
        const tempMessage = Object.assign(Object.assign({}, this.tempMessage(chatId)), { location });
        const message = {
            type: MessageContentsType.location,
            tmpSendId: tempMessage.tmpSendId.toString(),
            // @ts-expect-error
            location,
        };
        if (special) {
            tempMessage.special = special;
            message.special = special;
        }
        return [tempMessage, this.sendMessage(chatId, [message])];
    }
    /** Send a new message of text or media type */
    sendMessage(chatId, request) {
        return __awaiter(this, void 0, void 0, function* () {
            return getAxios()
                .then((axios) => {
                return axios.post(`${API_PATH.MESSAGES}/send/${chatId}`, request);
            })
                .then(({ data }) => data);
        });
    }
    /** Delete exists message */
    deleteMessage(messageId) {
        return __awaiter(this, void 0, void 0, function* () {
            yield getAxios()
                .then((axios) => {
                return axios.delete(`${API_PATH.MESSAGES}/${messageId}`);
            });
        });
    }
    /** Send reaction message */
    sendReactionMessage(messageId, request) {
        return __awaiter(this, void 0, void 0, function* () {
            yield getAxios()
                .then((axios) => {
                return axios.post(`${API_PATH.MESSAGES}/${messageId}/reactions`, request);
            });
        });
    }
    /** Remove message reaction */
    removeReactionMessage(messageId) {
        return __awaiter(this, void 0, void 0, function* () {
            yield getAxios()
                .then((axios) => {
                return axios.delete(`${API_PATH.MESSAGES}/${messageId}/reactions`);
            });
        });
    }
    tempMessage(chatId) {
        const _id = uuid();
        return {
            _id,
            /** Backward compatibility */
            messageId: _id,
            tmpSendId: _id,
            status: MESSAGE_STATUS.MESSAGE_SENT,
            incoming: false,
            direction: MESSAGE_DIRECTION.OUT,
            parent_chat: chatId,
            received: Date.now(),
        };
    }
}
__decorate([
    observable
], MessagesStore.prototype, "search", void 0);
__decorate([
    observable
], MessagesStore.prototype, "searchLoading", void 0);
__decorate([
    observable
], MessagesStore.prototype, "_searchResults", void 0);
__decorate([
    computed
], MessagesStore.prototype, "searchResults", null);
__decorate([
    computed
], MessagesStore.prototype, "searchHasMore", null);
__decorate([
    action
], MessagesStore.prototype, "setSearch", null);
__decorate([
    action
], MessagesStore.prototype, "sendMediaMessage", null);
export var MessagesSearchLoading;
(function (MessagesSearchLoading) {
    MessagesSearchLoading["ALL"] = "ALL";
    MessagesSearchLoading["MORE"] = "MORE";
    MessagesSearchLoading["NONE"] = "NONE";
})(MessagesSearchLoading || (MessagesSearchLoading = {}));
