import { isArray } from 'lodash';
import { shadeColor } from './functions';
export const parseCSSThemeColorsFromTheme = (theme) => {
    var _a, _b;
    if (!theme.color) {
        return {
            // placeholder colors
            backgroundColor: '#fff',
            background: '#fff',
            backgroundHovered: '#f5f5f5',
            backgroundInverted: '#fff',
            linkColor: '#1CC2FF',
            linkColorHover: '#3F51B5',
        };
    }
    const primaryColor = isArray(theme.color) ? theme.color[1] : theme.color;
    const linkColor = ((_a = theme.additionalData) === null || _a === void 0 ? void 0 : _a.darkTheme) ? '#1CC2FF' : '#1a237e';
    const linkColorHover = ((_b = theme.additionalData) === null || _b === void 0 ? void 0 : _b.darkTheme) ? shadeColor('#1CC2FF', -32) : '#3F51B5';
    let backgroundColor = isArray(theme.color) ? [theme.color[0], theme.color[1]] : theme.color;
    let backgroundColorHovered = isArray(theme.color)
        ? [theme.color[0], theme.color[1]]
        : theme.color;
    if (isArray(theme.color)) {
        backgroundColorHovered.push(shadeColor(theme.color[0], -25), shadeColor(theme.color[1], -25));
    }
    else {
        backgroundColorHovered = shadeColor(theme.color, -25);
    }
    const background = isArray(backgroundColor)
        ? `linear-gradient(270deg, ${backgroundColor[0]} 0%, ${backgroundColor[1]} 100%)`
        : backgroundColor;
    const backgroundHovered = isArray(backgroundColorHovered)
        ? `linear-gradient(270deg, ${backgroundColorHovered[0]} 0%, ${backgroundColorHovered[1]} 100%)`
        : backgroundColorHovered;
    const backgroundInverted = isArray(backgroundColor)
        ? `linear-gradient(90deg, ${backgroundColor[0]} 1.25%, ${backgroundColor[1]} 100%)`
        : backgroundColor;
    return { primaryColor, background, backgroundHovered, backgroundInverted, linkColor, linkColorHover };
};
