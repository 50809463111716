var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component } from 'react';
import { EntityManagerRoute, Operation } from '../EntityManager';
import AgentForm from './AgentForm';
import { observer } from 'mobx-react';
import { Trans, withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { pick } from 'lodash';
import UserItemLabel from "../Base/Items/UserItem/UserItemLabel";
import { withStore } from '../../store/rootStore';
import styles from './AgentsManager.module.scss';
import { Permissions } from '@whatsper/texterchat-common';
import { agentPropertiesEditable } from './helpers';
class AgentsManager extends Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                loading: false,
            }
        });
        Object.defineProperty(this, "getOpSupported", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { hasPermissions } = this.props.store.agentsStore;
                const op = [];
                if (hasPermissions([Permissions.Permission.users_view])) {
                    op.push(Operation.LIST);
                }
                if (hasPermissions([Permissions.Permission.users_manage])) {
                    op.push(Operation.CREATE, Operation.EDIT);
                }
                return op;
            }
        });
        Object.defineProperty(this, "renderOperationTitle", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (op, agent) => {
                switch (op) {
                    case Operation.LIST:
                        return (React.createElement(Trans, { i18nKey: "agentsManager" }));
                    case Operation.CREATE:
                        return (React.createElement(Trans, { i18nKey: "addAgent" }));
                    case Operation.EDIT:
                        return (React.createElement(Trans, { i18nKey: "editAgentName", values: { name: agent ? this.getName(agent) : null } }));
                    case Operation.DELETE:
                        return (React.createElement(Trans, { i18nKey: "deleteAgentName", values: { name: agent ? this.getName(agent) : null } }));
                }
            }
        });
        Object.defineProperty(this, "renderDeleteQuestion", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (agent) => {
                return (React.createElement(Trans, { i18nKey: "sureDeleteName", values: { name: this.getName(agent) } }));
            }
        });
        Object.defineProperty(this, "sortAgents", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (a, b) => {
                if (!a.temporary && !a.disabled && (b.temporary || b.disabled)) {
                    return -1;
                }
                if (a.temporary && !a.disabled && b.disabled) {
                    return -1;
                }
                return 1;
            }
        });
        Object.defineProperty(this, "agentSave", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (formData, agent) => __awaiter(this, void 0, void 0, function* () {
                this.setState({ loading: true });
                try {
                    const { agentsStore } = this.props.store;
                    let userData = agent ? pick(agent, [...agentPropertiesEditable, 'uid']) : {};
                    userData = Object.assign(Object.assign({}, userData), pick(formData, agentPropertiesEditable));
                    if (userData.color === '') {
                        delete userData.color;
                    }
                    if (agent) {
                        yield agentsStore
                            .editUser(Object.assign(Object.assign({}, userData), { uid: agent.uid }), formData.departments);
                    }
                    else {
                        yield agentsStore.createUser(
                        // @ts-expect-error // TODO: Think more about this implementation in side of form data types
                        userData, formData.departments);
                    }
                }
                finally {
                    this.setState({ loading: false });
                }
            })
        });
        Object.defineProperty(this, "isDisabled", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (agent) => {
                return agent.disabled;
            }
        });
        Object.defineProperty(this, "agentDelete", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (agent) => __awaiter(this, void 0, void 0, function* () {
                this.setState({ loading: true });
                try {
                    yield this
                        .props
                        .store
                        .agentsStore
                        .deleteUser(agent.uid);
                }
                finally {
                    this.setState({ loading: false });
                }
            })
        });
    }
    render() {
        const { agentsStore, departmentsStore } = this.props.store;
        return (React.createElement(EntityManagerRoute, { path: "/manage/agents", entities: agentsStore.users, entityIdKey: "uid", teaser: AgentTeaser, teaserProps: { departments: departmentsStore.departments }, form: AgentForm, renderOperationTitle: this.renderOperationTitle, renderDeleteQuestion: this.renderDeleteQuestion, disabled: () => false, opSupported: this.getOpSupported(), onEntityDelete: (agent) => this.agentDelete(agent), onEntitySave: (data, agent) => __awaiter(this, void 0, void 0, function* () { yield this.agentSave(data, agent); }), loading: this.state.loading, sorter: this.sortAgents }));
    }
    getName(agent) {
        return agent.displayName || agent.email || agent.uid;
    }
}
class DepartmentsList extends Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "getList", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (this.props.departments) {
                    return this.props.departments.filter(department => { var _a; return (_a = department.agents) === null || _a === void 0 ? void 0 : _a.includes(this.props.entity.uid); });
                }
                return [];
            }
        });
        Object.defineProperty(this, "render", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                return (React.createElement("div", { className: styles.userDepartment }, this.getList().map((department, i) => React.createElement(UserItemLabel, { key: i, department: department }))));
            }
        });
    }
}
function AgentTeaser({ entity, departments }) {
    return (React.createElement("div", { className: classNames(styles.teaserContainer, {
            [styles.disabled]: entity.disabled,
        }) },
        React.createElement("div", { className: styles.agentColor, style: { backgroundColor: entity.color } }),
        React.createElement("div", { className: styles.agentDetails },
            React.createElement("span", null, entity.displayName || entity.email || entity.uid),
            React.createElement(DepartmentsList, { entity: entity, departments: departments }))));
}
export default withTranslation()(withStore(observer(AgentsManager)));
