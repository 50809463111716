import React from 'react';
import { observer } from 'mobx-react';
import Avatar from '../Base/User/Avatar';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import { USER_STATES } from '../../constants';
import { useStore } from '../../store/rootStore';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import './TopMobileDrawer.scss';
const TopMobileDrawer = ({ toggleMessageDrawer, toggleDrawer }) => {
    var _a, _b;
    const { loginStore, statusStore } = useStore();
    const history = useHistory();
    function logOut() {
        loginStore.logOut().then(() => {
            if (loginStore.userState === USER_STATES.LOGGED_OUT) {
                history.push('/login');
            }
        });
    }
    return (React.createElement("div", { className: "mobile-menu" },
        React.createElement("div", { className: "mobile-menu__profile" },
            React.createElement(Avatar, { displayName: ((_a = loginStore.userDetails) === null || _a === void 0 ? void 0 : _a.displayName) || '', src: "https://cdn.iconscout.com/icon/free/png-256/avatar-370-456322.png", status: statusStore.status, uiAvatarProps: {
                    className: 'mobile-menu__avatar',
                } }),
            React.createElement("div", null, (_b = loginStore.userDetails) === null || _b === void 0 ? void 0 : _b.displayName)),
        React.createElement("div", { className: "mobile-menu__buttons" },
            React.createElement("div", { className: "mobile-menu__button", onClick: toggleMessageDrawer },
                React.createElement(Button, null,
                    React.createElement(ChatOutlinedIcon, { className: "menu-button_color" })),
                React.createElement(Trans, { i18nKey: "sendNewMessage" })),
            React.createElement("div", { onClick: toggleDrawer, className: "mobile-menu__button" },
                React.createElement(Button, null,
                    React.createElement(SettingsOutlinedIcon, { className: "menu-button_color" })),
                React.createElement(Trans, { i18nKey: "Settings" })),
            React.createElement("div", { className: "mobile-menu__button" }),
            React.createElement("div", { onClick: logOut, className: "mobile-menu__button" },
                React.createElement(Button, { size: "large", type: "ghost" },
                    React.createElement(PowerSettingsNewIcon, { className: "menu-button_color" })),
                React.createElement(Trans, { i18nKey: "logout" })))));
};
export default observer(TopMobileDrawer);
