import React from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { CHAT_STATUS } from '../../../../constants';
import styles from './TopSideBar.module.scss';
import TopBarButton from './atoms/TopBarButton';
import { useStore } from '../../../../store/rootStore';
import { CHATS_GROUP } from '../../../../store/ChatsStore';
import useCheckIfMobile from '../../../../helpers/hooks/useCheckIfMobile';
const TopSideBar = () => {
    const store = useStore();
    const history = useHistory();
    const isMobile = useCheckIfMobile();
    const { activeChatStore, chatsStore } = store;
    const { count, countLoading, currentGroup } = chatsStore;
    const onTabSelected = (chatsGroup) => {
        const { activeChatStore, chatsStore } = store;
        if (chatsStore.currentGroup !== chatsGroup) {
            chatsStore.setCurrentGroup(chatsGroup);
            activeChatStore.removeActiveChat();
        }
        const chatList = document.getElementById('chatsList');
        if (chatList) {
            chatList.scrollTop = 0;
        }
        history.push('/');
    };
    if (activeChatStore.chat && isMobile) {
        return null;
    }
    return (React.createElement("div", { className: styles.topSidebar },
        React.createElement(TopBarButton, { status: CHAT_STATUS.PENDING, numberOfChats: count.status.PENDING, handleClick: () => onTabSelected(CHATS_GROUP.PENDING), active: currentGroup === CHATS_GROUP.PENDING, loading: countLoading }),
        React.createElement(TopBarButton, { status: CHAT_STATUS.ASSIGNED, numberOfChats: count.status.ASSIGNED, handleClick: () => onTabSelected(CHATS_GROUP.ASSIGNED), active: currentGroup === CHATS_GROUP.ASSIGNED, loading: countLoading }),
        React.createElement(TopBarButton, { numberOfChats: count.all, handleClick: () => onTabSelected(CHATS_GROUP.ALL), active: currentGroup === CHATS_GROUP.ALL, loading: countLoading }),
        React.createElement(TopBarButton, { status: CHAT_STATUS.BOT, numberOfChats: count.status.BOT, handleClick: () => onTabSelected(CHATS_GROUP.BOT), active: currentGroup === CHATS_GROUP.BOT, loading: countLoading }),
        React.createElement(TopBarButton, { status: CHAT_STATUS.RESOLVED, numberOfChats: count.status.RESOLVED, handleClick: () => onTabSelected(CHATS_GROUP.RESOLVED), active: currentGroup === CHATS_GROUP.RESOLVED, loading: countLoading }),
        React.createElement(TopBarButton, { status: CHAT_STATUS.BULK, numberOfChats: count.status.BULK, handleClick: () => onTabSelected(CHATS_GROUP.BULK), active: currentGroup === CHATS_GROUP.BULK, loading: countLoading })));
};
export default observer(TopSideBar);
