import { isSpecialMessage } from '@whatsper/texterchat-common';
import React from 'react';
import WhatsAppMessage from './messages/WhatsAppMessage';
import s from './SpecialMessage.module.scss';
const SpecialMessage = ({ message, timestamp, status, }) => {
    if (isSpecialMessage(message)) {
        return (React.createElement("div", { dir: "auto", className: s.specialContainer }, message.special.whatsapp && (React.createElement(WhatsAppMessage, { message: message.special.whatsapp, text: message.text, timestamp: timestamp, status: status }))));
    }
    return null;
};
export default SpecialMessage;
