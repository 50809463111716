var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { EventName, Routes } from '@whatsper/texterchat-common';
import { action, makeObservable, observable, reaction, runInAction } from 'mobx';
import { getAxios } from '../../backend';
import { API_PATH, APP_STATE, USER_STATES } from '../../constants';
import { clearComposeSessionState } from '../../helpers';
import { throttle } from 'lodash';
const windowActivityEvents = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];
const CHECK_LOGIN_INTERVAL_MS = 1000 * 15; // 15 seconds
const AWAY_TIMEOUT_MS = 1000 * 60 * 5; // 5 minutes
class LoginStore {
    constructor(rootStore) {
        var _a;
        Object.defineProperty(this, "rootStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "loading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "errorMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ''
        });
        Object.defineProperty(this, "userDetails", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "userState", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: USER_STATES.LOGGED_IN
        });
        Object.defineProperty(this, "sessionActive", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        /** Authentication methods params */
        Object.defineProperty(this, "availableAuthMethods", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (_a = window.env.initial_settings) === null || _a === void 0 ? void 0 : _a.authMethods
        });
        Object.defineProperty(this, "authMethods", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: this.availableAuthMethods
        });
        Object.defineProperty(this, "challenge", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 'none'
        });
        Object.defineProperty(this, "mfa", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "shouldUpdatePassword", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        /* Reminder to return password reset functionality */
        Object.defineProperty(this, "showPasswordReset", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "awayTimeoutId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "checkIfUserIsLoggedInIntervalId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "setup", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                console.debug('Setting up login store listeners and timers');
                this.attachActivityListeners();
                this.onActivity();
            }
        });
        Object.defineProperty(this, "shutdown", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                console.debug('Shutting down login store listeners and timers');
                this.detachActivityListeners();
                this.onAway();
                this._resetAuthMethodParams();
            }
        });
        Object.defineProperty(this, "attachActivityListeners", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                windowActivityEvents.forEach((event) => {
                    window.addEventListener(event, this.onActivity, true);
                });
            }
        });
        Object.defineProperty(this, "detachActivityListeners", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                windowActivityEvents.forEach((event) => {
                    window.removeEventListener(event, this.onActivity, true);
                });
            }
        });
        Object.defineProperty(this, "startTryingToLoginUser", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (this.checkIfUserIsLoggedInIntervalId)
                    return;
                const id = setInterval(() => {
                    this.getCurrentUser(true);
                }, CHECK_LOGIN_INTERVAL_MS);
                this.checkIfUserIsLoggedInIntervalId = id;
            }
        });
        Object.defineProperty(this, "stopTryingToLoginUser", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (this.checkIfUserIsLoggedInIntervalId) {
                    clearInterval(this.checkIfUserIsLoggedInIntervalId);
                    this.checkIfUserIsLoggedInIntervalId = null;
                }
            }
        });
        Object.defineProperty(this, "onActivity", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: throttle(() => {
                if (this.awayTimeoutId) {
                    clearTimeout(this.awayTimeoutId);
                }
                this.startTryingToLoginUser();
                this.awayTimeoutId = setTimeout(() => this.onAway(), AWAY_TIMEOUT_MS);
            }, 1000, { leading: true })
        });
        Object.defineProperty(this, "onAway", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.stopTryingToLoginUser();
            }
        });
        Object.defineProperty(this, "setUser", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (user) => {
                this.userState = USER_STATES.LOGGED_IN;
                this.userDetails = user;
                // WARNING: Continuing bad design choice of previous versions
                // it is sad that we need to do this again.
                // Please don't copy this sh*t
                if (user.roles) {
                    if (user.roles.includes('limited')) {
                        console.log('Setting "only_own" chats filter for limited agent');
                        this.rootStore.chatsStore.setDepartmentConditionFilter(Routes.Chats.DepartmentConditionFilter.only_own);
                    }
                    else if (user.roles.includes('agent')) {
                        console.log('Setting "own_and_unassigned" chats filter for agent');
                        this.rootStore.chatsStore.setDepartmentConditionFilter(Routes.Chats.DepartmentConditionFilter.own_and_unassigned);
                    }
                    else if (this.rootStore.chatsStore.searchFilter.departmentConditionFilter) {
                        console.log('Clearing department condition filter');
                        this.rootStore.chatsStore.setDepartmentConditionFilter(null);
                    }
                }
            }
        });
        Object.defineProperty(this, "getCurrentUser", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (silent = false) => __awaiter(this, void 0, void 0, function* () {
                console.log('getting user');
                if (!silent) {
                    runInAction(() => (this.loading = true));
                }
                return getAxios()
                    .then((axios) => {
                    return axios.get(`${API_PATH.AUTH}/me`);
                })
                    .then(({ data }) => {
                    var _a;
                    if (!silent) {
                        runInAction(() => (this.loading = false));
                    }
                    /* Skip if user is already logged in */
                    if (data.user._id === ((_a = this.userDetails) === null || _a === void 0 ? void 0 : _a._id)) {
                        this.stopTryingToLoginUser();
                        return;
                    }
                    this.onLogin(data.user);
                })
                    .catch((error) => {
                    if (!silent) {
                        console.error('Error loading current user', error);
                        runInAction(() => {
                            this.userState = USER_STATES.LOGGED_OUT;
                            this.loading = false;
                        });
                    }
                    else {
                        console.debug("Can't load current user");
                    }
                });
            })
        });
        Object.defineProperty(this, "setAuthMethods", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (methods) => {
                this.authMethods = methods;
            }
        });
        Object.defineProperty(this, "loginWithPassword", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (email, password, newPassword) => __awaiter(this, void 0, void 0, function* () {
                const url = `${API_PATH.AUTH}/methods/password/login`;
                const formData = { email, password };
                if (newPassword) {
                    formData['new_password'] = newPassword;
                }
                return getAxios()
                    .then((axios) => {
                    return axios.post(url, formData);
                })
                    .then(({ data }) => {
                    if (data.challenge === 'update_password') {
                        runInAction(() => {
                            this.errorMessage = 'Please update your password.';
                            this.shouldUpdatePassword = true;
                        });
                    }
                    else {
                        runInAction(() => {
                            this.shouldUpdatePassword = false;
                        });
                        return this._handleLogInResponse(data);
                    }
                })
                    .catch((error) => {
                    runInAction(() => {
                        this.errorMessage = error.message;
                    });
                    console.error(error);
                })
                    .finally(() => {
                    runInAction(() => {
                        this.loading = false;
                    });
                });
            })
        });
        Object.defineProperty(this, "loginWithWhatsAppOTP", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (code) => __awaiter(this, void 0, void 0, function* () {
                const url = `${API_PATH.AUTH}/methods/whatsapp-otp/login`;
                const formData = this.mfa ? { mfa: this.mfa } : {};
                if (code) {
                    formData['code'] = code;
                }
                else {
                    formData['send'] = true;
                }
                return getAxios()
                    .then((axios) => {
                    return axios.post(url, formData);
                })
                    .then(({ data }) => {
                    this._handleLogInResponse(data);
                })
                    .catch((error) => {
                    runInAction(() => {
                        this.errorMessage = error.message;
                    });
                    console.error(error);
                })
                    .finally(() => {
                    runInAction(() => {
                        this.loading = false;
                    });
                });
            })
        });
        Object.defineProperty(this, "loginWithTOTP", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                const url = `${API_PATH.AUTH}/methods/totp/login`;
                console.info('Not implemented');
                return;
            })
        });
        Object.defineProperty(this, "getSAMLInfo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                const url = `${API_PATH.AUTH}/methods/saml/info`;
                return getAxios()
                    .then((axios) => {
                    return axios.get(url);
                })
                    .then(({ data }) => {
                    return data;
                })
                    .catch((error) => {
                    console.error(error);
                });
            })
        });
        Object.defineProperty(this, "initSAMLogin", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (binding) => __awaiter(this, void 0, void 0, function* () {
                const url = `${API_PATH.AUTH}/methods/saml/login/init/${binding}`;
                return getAxios()
                    .then((axios) => {
                    return axios.get(url);
                })
                    .then(({ data }) => {
                    return data;
                })
                    .catch((error) => {
                    console.error(error);
                });
            })
        });
        Object.defineProperty(this, "_handleLogInResponse", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (response) => {
                runInAction(() => {
                    this.challenge = response.challenge;
                });
                if (response.challenge === 'mfa') {
                    const { token, uid, methods } = response;
                    runInAction(() => {
                        this.loading = false;
                        this.mfa = { token, uid };
                        this.authMethods = methods;
                    });
                }
                else {
                    return this.getCurrentUser();
                }
            }
        });
        Object.defineProperty(this, "_resetAuthMethodParams", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                runInAction(() => {
                    this.authMethods = this.availableAuthMethods;
                    this.challenge = 'none';
                    this.mfa = null;
                    this.shouldUpdatePassword = false;
                });
            }
        });
        Object.defineProperty(this, "logOut", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (all) => __awaiter(this, void 0, void 0, function* () {
                runInAction(() => {
                    this.loading = true;
                });
                yield getAxios()
                    .then((axios) => {
                    return axios.post(`${API_PATH.AUTH}/logout`, { all }).then(() => {
                        this.onLogOut();
                        /* Can't reach here anymore, 401 */
                        // return axios.post(API_PATH.HEARTBEAT, {status: 'offline'});
                    });
                })
                    .catch((error) => {
                    console.error(error);
                })
                    .finally(() => {
                    runInAction(() => {
                        this.loading = false;
                    });
                });
            })
        });
        Object.defineProperty(this, "onLogin", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (user) => __awaiter(this, void 0, void 0, function* () {
                console.debug('onLogin() called');
                if (window.parent && window.parent.postMessage) {
                    window.parent.postMessage({ userLoggedIn: user.email }, '*');
                }
                return this.startSession(user);
            })
        });
        Object.defineProperty(this, "onLogOut", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                console.debug('onLogOut() called');
                if (window.parent && window.parent.postMessage) {
                    window.parent.postMessage({ userLoggedIn: false }, '*');
                }
                this.userState = USER_STATES.LOGGED_OUT;
                this.userDetails = null;
                this.stopSession();
            }
        });
        Object.defineProperty(this, "stopSession", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.sessionActive = false;
                this.rootStore.events.disconnect();
                this.rootStore.resetSettings();
                this.rootStore.reset();
                this.rootStore.setReadyState(APP_STATE.READY);
            }
        });
        Object.defineProperty(this, "startSession", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (user) => __awaiter(this, void 0, void 0, function* () {
                if (!this.rootStore.events.connected) {
                    this.rootStore.events.connect();
                }
                this.rootStore.getTimeFormat();
                this.setUser(user);
                yield this.rootStore.loadGeneralSettings();
                this.rootStore.crmStore.crmGetFields();
                yield Promise.all([
                    this.rootStore.departmentsStore.fetchDepartments(),
                    this.rootStore.agentsStore.fetchUsers(),
                    this.rootStore.channels.fetchChannelsAdapters(),
                    this.rootStore.channels.fetchChannelsAccounts(),
                    this.rootStore.agentsStore.getRoles(),
                ]);
                // TODO: Merge loggedInUser with logged in agent details.
                yield Promise.all([
                    this.rootStore.chatsStore.loadInitial(),
                    this.rootStore.quickRepliesStore.loadQuickReplies(),
                    this.rootStore.checkBuildIdVersion(true),
                    clearComposeSessionState(),
                ]);
                this.rootStore.setReadyState(APP_STATE.READY);
                this.sessionActive = true;
                this.rootStore.notificationsStore.askNotificationPermission();
                if (this.rootStore.webViewInjectedData.fcmToken) {
                    this.rootStore.agentsStore.saveUserFCMToken(user.uid, this.rootStore.webViewInjectedData.fcmToken);
                }
            })
        });
        Object.defineProperty(this, "startInactiveTabSession", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                // Re-load everything
                if (this.userDetails) {
                    this.startSession(this.userDetails);
                }
                else {
                    console.warn('User details not available');
                }
            })
        });
        makeObservable(this);
        this.rootStore = rootStore;
        this.rootStore.events.addListener(EventName.user_updated, (data) => this.onUserUpdatedEvent(data));
        reaction(() => this.userState, () => {
            if (this.userState === USER_STATES.LOGGED_OUT) {
                this.setup();
            }
            else {
                this.shutdown();
            }
        });
    }
    onUserUpdatedEvent(user) {
        var _a;
        if (((_a = this.userDetails) === null || _a === void 0 ? void 0 : _a._id) === user._id) {
            this.userDetails = Object.assign({}, user);
        }
    }
}
__decorate([
    observable
], LoginStore.prototype, "loading", void 0);
__decorate([
    observable
], LoginStore.prototype, "errorMessage", void 0);
__decorate([
    observable
], LoginStore.prototype, "userDetails", void 0);
__decorate([
    observable
], LoginStore.prototype, "userState", void 0);
__decorate([
    observable
], LoginStore.prototype, "sessionActive", void 0);
__decorate([
    observable
], LoginStore.prototype, "authMethods", void 0);
__decorate([
    observable
], LoginStore.prototype, "challenge", void 0);
__decorate([
    observable
], LoginStore.prototype, "mfa", void 0);
__decorate([
    observable
], LoginStore.prototype, "shouldUpdatePassword", void 0);
__decorate([
    observable
], LoginStore.prototype, "awayTimeoutId", void 0);
__decorate([
    observable
], LoginStore.prototype, "checkIfUserIsLoggedInIntervalId", void 0);
__decorate([
    action
], LoginStore.prototype, "setUser", void 0);
__decorate([
    action
], LoginStore.prototype, "setAuthMethods", void 0);
__decorate([
    action
], LoginStore.prototype, "loginWithPassword", void 0);
__decorate([
    action
], LoginStore.prototype, "loginWithWhatsAppOTP", void 0);
__decorate([
    action
], LoginStore.prototype, "loginWithTOTP", void 0);
__decorate([
    action
], LoginStore.prototype, "getSAMLInfo", void 0);
__decorate([
    action
], LoginStore.prototype, "logOut", void 0);
__decorate([
    action
], LoginStore.prototype, "onLogin", void 0);
__decorate([
    action
], LoginStore.prototype, "onLogOut", void 0);
__decorate([
    action
], LoginStore.prototype, "stopSession", void 0);
__decorate([
    action
], LoginStore.prototype, "startSession", void 0);
__decorate([
    action
], LoginStore.prototype, "startInactiveTabSession", void 0);
__decorate([
    action
], LoginStore.prototype, "onUserUpdatedEvent", null);
export default LoginStore;
