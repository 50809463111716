export const agentPropertiesEditable = [
    'displayName',
    'email',
    'disabled',
    'contacts',
    'crmId',
    'roles',
    'color',
    'password',
];
