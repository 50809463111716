import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { IoMdArrowBack } from 'react-icons/io';
import { useStore } from '../../../store/rootStore';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import MessagesSearchList from '../ChatsList/MessagesSearchList';
import { MessagesSearchLoading } from '../../../store/MessagesStore';
import { getDirection } from '../../../helpers';
import { Trans, useTranslation } from 'react-i18next';
import { MyInput } from '../../Base/Form';
import s from './ChatSearch.module.scss';
const ChatSearch = ({ onMessageSelected, onClose }) => {
    const { activeChatStore } = useStore();
    const { chat, setInChatSearchQuery, inChatSearchQuery, inChatSearchLoading, inChatSearchResults, inChatSearchHasMore, searchInChatMessages, theme, } = activeChatStore;
    const { t } = useTranslation();
    const loading = inChatSearchLoading !== MessagesSearchLoading.NONE;
    const onResultClick = (messageId) => {
        if (typeof onMessageSelected === 'function') {
            onMessageSelected(messageId);
        }
    };
    return (React.createElement("div", { className: classNames(s.wrapper, {
            [s.rtl]: getDirection() === 'rtl',
        }) },
        React.createElement("div", { className: s.header },
            typeof onClose === 'function' && (React.createElement("div", { className: s.closeModal },
                React.createElement(IoMdArrowBack, { onClick: onClose }))),
            React.createElement("div", { className: s.title },
                React.createElement(Trans, { i18nKey: "searchMessages" }))),
        React.createElement("div", { className: s.body },
            React.createElement("div", { className: s.inputWrapper },
                React.createElement(MyInput, { value: inChatSearchQuery, onChangeHandler: setInChatSearchQuery, className: s.searchInput, placeholder: t('searchPlaceholder'), allowClear: !loading, suffix: loading && React.createElement(LoadingOutlined, null), type: "search", autoFocus: true }),
                !!inChatSearchResults.totalHits && (React.createElement("div", { className: s.numberOfResults },
                    inChatSearchResults.totalHits,
                    " results found:"))),
            React.createElement("div", { className: s.content }, inChatSearchLoading === MessagesSearchLoading.ALL ? (React.createElement("div", { className: s.loadingWrapper },
                React.createElement(LoadingOutlined, null))) : !inChatSearchQuery ? (React.createElement("div", { className: s.emptyContainer },
                React.createElement("div", { className: s.emptyText }, t('searchMessagesWith', {
                    title: chat === null || chat === void 0 ? void 0 : chat.title,
                })))) : inChatSearchResults.messages.length === 0 ? (React.createElement("div", { className: s.emptyContainer },
                React.createElement("div", { className: s.noResultsContentWrapper },
                    React.createElement(SearchOutlined, { className: s.emptyIcon }),
                    React.createElement("div", { className: s.emptyText }, t('noResults'))))) : (React.createElement(MessagesSearchList, { className: s.resultsList, header: null, search: inChatSearchQuery, results: inChatSearchResults.messages, loading: inChatSearchLoading, hasMore: inChatSearchHasMore, onLoadMore: () => searchInChatMessages(true, inChatSearchQuery), onClickMessage: onResultClick, inChatSearch: true }))))));
};
export default observer(ChatSearch);
