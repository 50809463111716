import React from 'react';
import classNames from 'classnames';
import { MESSAGE_DIRECTION } from '@whatsper/texterchat-common';
import Video from '../../../../../../components/icons/video';
import { getFilePreviewUrl } from '../../../../../../store/FilesStore';
import styles from './VideoPostback.module.scss';
const VideoPostback = ({ context, name, type, incoming, color, invertChatColor }) => {
    let { fileId, url, caption, contentType } = context.media[0];
    if (fileId) {
        url = getFilePreviewUrl(fileId);
    }
    return (React.createElement("div", { className: classNames(styles.main, {
            [styles.reply]: type === 'reply',
        }) },
        React.createElement("div", { className: styles.info },
            React.createElement("p", { className: classNames(styles.name, {
                    [styles.incoming]: incoming,
                    [styles.invertColors]: !incoming && invertChatColor && type === 'message',
                }) }, context.direction === MESSAGE_DIRECTION.IN ? name : 'You'),
            React.createElement("div", { className: classNames(styles.text, {
                    [styles.reply]: type === 'reply',
                    [styles.invertColors]: !incoming && type === 'message',
                }) },
                React.createElement(Video, { color: color, width: 24, height: 24 }),
                caption || 'Video')),
        React.createElement("video", { className: styles.video },
            React.createElement("source", { src: url, type: contentType }))));
};
export default VideoPostback;
