import React from 'react';
import moment from 'moment';
import { Checkbox } from 'antd';
import s from '../FilesMediaLinks.module.scss';
const MediaHeader = ({ mediaArray, selectedFiles, onMultipleFileSelect, date }) => {
    return (React.createElement("div", { className: s.dateHeader },
        React.createElement("div", { className: s.dateHeaderTitle }, moment(date).format('D MMM, YYYY')),
        React.createElement(Checkbox, { onClick: () => onMultipleFileSelect(mediaArray.map((f) => { var _a, _b; return (_b = (_a = f.fileId) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : ''; }).filter(Boolean)), checked: mediaArray.every(({ fileId }) => { var _a; return selectedFiles.includes((_a = fileId === null || fileId === void 0 ? void 0 : fileId.toString()) !== null && _a !== void 0 ? _a : ''); }), className: s.checkbox })));
};
export default MediaHeader;
