import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CHATS_GROUP } from '../../../../store/ChatsStore';
import { useStore } from '../../../../store/rootStore';
import { Permissions } from '@whatsper/texterchat-common';
import useCheckIfMobile from '../../../../helpers/hooks/useCheckIfMobile';
import styles from '../ChatsList.module.scss';
export var DropdownOptions;
(function (DropdownOptions) {
    DropdownOptions["RESOLVE_CHATS"] = "resolveChats";
})(DropdownOptions || (DropdownOptions = {}));
const dropdownMenu = ({ resolve, setDropdownOption, filtersApplied, agents, departments, clearAllFiltersButton, }) => {
    const { chatsStore, agentsStore } = useStore();
    const isMobile = useCheckIfMobile();
    const { t } = useTranslation();
    const menu = () => {
        const { currentGroup, totalCount } = chatsStore;
        const items = [
            ...(isMobile
                ? [
                    {
                        key: 'header',
                        style: { pointerEvents: 'none' },
                        label: (React.createElement("div", { className: styles.dropdownHeader },
                            "Filters applied: ",
                            React.createElement("span", null, filtersApplied))),
                    },
                ]
                : []),
            ...(agentsStore.hasPermissions([Permissions.Permission.chats_bulk_resolve]) && !isMobile
                ? [
                    {
                        key: 'resolveChats',
                        label: (React.createElement(Trans, { i18nKey: 'resolveChats', values: {
                                status: currentGroup === CHATS_GROUP.PENDING ? t('pending') : t('taken'),
                            } })),
                        onClick: () => setDropdownOption(DropdownOptions.RESOLVE_CHATS),
                        disabled: !resolve || totalCount === 0,
                    },
                ]
                : [
                    {
                        key: 'departments',
                        label: (React.createElement("div", { className: styles.dropdownItemWrapper },
                            React.createElement("span", null,
                                React.createElement(Trans, { i18nKey: "departments" }),
                                ":"),
                            departments)),
                    },
                    {
                        key: 'agents',
                        label: (React.createElement("div", { className: styles.dropdownItemWrapper },
                            React.createElement("span", null,
                                React.createElement(Trans, { i18nKey: "agents" }),
                                ":"),
                            agents)),
                    },
                    {
                        key: 'clearAllFilters',
                        label: React.createElement("div", { className: styles.dropdownItemWrapper }, clearAllFiltersButton),
                    },
                ]),
        ];
        return { items };
    };
    return menu();
};
export default dropdownMenu;
