import React from 'react';
import { observer } from 'mobx-react';
import { MessagesSearchLoading } from '../../../store/MessagesStore';
import MessagesSearchHit from '../MessagesSearchHit';
import LazyLoadList from '../../Base/Utils/LazyLoadList';
import classNames from 'classnames';
import { formatRelativeTime } from '../../../helpers';
import { useStore } from '../../../store/rootStore';
import { useTranslation } from 'react-i18next';
import s from './ChatsList.module.scss';
const MessagesSearchList = ({ results, id, className, search, loading, hasMore, onClickMessage, onLoadMore, header, inChatSearch, skeletonCountDefault = 10, skeletonCountMax = 20, }) => {
    const { timeFormat } = useStore();
    const { t } = useTranslation();
    const renderItems = () => {
        if (loading === MessagesSearchLoading.ALL) {
            // TODO: Skeleton animation???
            return null;
        }
        return results.map((hit) => {
            return (React.createElement(MessagesSearchHit, { key: hit.id, search: search, hit: hit, className: classNames({
                    'in-chat-search': inChatSearch,
                }), highlightedClassName: classNames('highlighted', {
                    'in-chat-search-highlighted': inChatSearch,
                }), onClick: () => (onClickMessage ? onClickMessage(hit.id, hit.chatId) : null), renderHeader: (hit) => (React.createElement("div", { className: s.headerWrapper },
                    React.createElement("div", { className: s.title }, hit.agentUid ? 'You' : hit.chatTitle),
                    React.createElement("div", { className: s.timestamp }, formatRelativeTime(hit.date, timeFormat, t)))) }));
        });
    };
    return (React.createElement(LazyLoadList, { id: id, className: className, loadingNext: loading === MessagesSearchLoading.MORE, canLoad: loading === MessagesSearchLoading.NONE, hasNext: hasMore, onLoadNext: () => onLoadMore === null || onLoadMore === void 0 ? void 0 : onLoadMore() },
        header,
        renderItems()));
};
export default observer(MessagesSearchList);
