import { Button, Upload, message } from 'antd';
import { Trans } from 'react-i18next';
import { pick } from 'lodash';
import React from 'react';
import { MEDIA_TYPE } from '@whatsper/texterchat-common';
import classNames from 'classnames';
import { ReactComponent as QuickRepliesPlus } from '../../../assets/icons/plus/quickRepliesPlus.svg';
import { checkFileMaxSize } from '../ChatWindow/helpers';
import { fileEnsureUid } from '../../../helpers/file';
import { truncateFilename } from '../../../helpers';
import Trashbin from '../../../components/icons/trashbin';
import s from './FilePreview.module.scss';
const Controls = ({ files, selectedFileUid, onRemove, onAdd, onSelect, mediaLimits, renderIconByExt, }) => {
    var _a;
    if (files && files.length < 1)
        return null;
    let accept = Object.values(pick(mediaLimits, ['video', 'image'])).map(({ types }) => types);
    if (mediaLimits === null || mediaLimits === void 0 ? void 0 : mediaLimits.audio)
        accept.push(mediaLimits.audio.types);
    if ((_a = mediaLimits === null || mediaLimits === void 0 ? void 0 : mediaLimits.document) === null || _a === void 0 ? void 0 : _a.types) {
        accept.push(mediaLimits.document.types);
    }
    else {
        accept = ['*'];
    }
    const onAddFiles = (info) => {
        const rawFiles = info.fileList.map((f) => f.originFileObj);
        if (!beforeUpload(rawFiles))
            return;
        const filesToAdd = rawFiles
            .map((file) => {
            let type = file.type.split('/')[0];
            if (!Object.values(MEDIA_TYPE).includes(type)) {
                type = MEDIA_TYPE.DOC;
            }
            return { file: fileEnsureUid(file), type };
        })
            .filter(({ file }) => !files.find((f) => f.file.uid === file.uid));
        onAdd(filesToAdd);
    };
    const beforeUpload = (files) => {
        const checkStatuses = files.map((file) => {
            if (mediaLimits) {
                const maxSizeError = checkFileMaxSize(file, mediaLimits);
                if (maxSizeError) {
                    message.error(React.createElement(Trans, { i18nKey: maxSizeError.key, values: { size: maxSizeError.size } }));
                    return false;
                }
            }
            return true;
        });
        return checkStatuses.every((check) => check);
    };
    return (React.createElement("div", { className: s.controlsContainer },
        files.map(({ file, type }, i) => {
            const selected = selectedFileUid === file.uid;
            const name = truncateFilename(file.name);
            const size = file
                ? file.size / 1000 > 1000
                    ? `${(file.size / 1000000).toFixed(2)} MB`
                    : `${(file.size / 1000).toFixed(2)} KB`
                : '';
            const ext = file.name.split('.').pop() || '';
            return (React.createElement("div", { key: `${file.uid}-${i}`, className: classNames(s.controlsBoxPreview, {
                    [s.selected]: selected,
                }), onClick: () => {
                    if (!selected) {
                        onSelect(file, type);
                    }
                } },
                React.createElement("div", { className: classNames(s.previewBox, {
                        [s.selected]: selected,
                    }) },
                    React.createElement("div", { className: s.previewIcon }, renderIconByExt(ext)),
                    React.createElement("div", { className: s.previewText },
                        React.createElement("div", { className: s.previewTitle }, name),
                        React.createElement("div", { className: s.previewSize }, size))),
                selected && (React.createElement("div", { className: s.controlsRemove, onClick: () => onRemove(file.uid) },
                    React.createElement(Trashbin, { color: "#EB584E" })))));
        }),
        React.createElement(Upload, { showUploadList: false, beforeUpload: () => false, onChange: onAddFiles, accept: accept.join(','), multiple: true },
            React.createElement(Button, { className: s.controlsAdd },
                React.createElement(QuickRepliesPlus, null),
                "Add Files"))));
};
export default Controls;
