var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/**
 * IMPORTANT!!!
 * If don't await some async function completion inside other async function,
 * change applied to some observable value in mobx store iside that not awaited async function,
 * even when properly called inside of runInAction(), can sometimes not trigger react component re-render!
 * Always await all nested async functions call tree if inside deepest async function some mobx store value is changed!
 */
import React, { createContext, useContext } from 'react';
import { action, configure, observable, computed, runInAction, makeObservable, } from 'mobx';
import axios from 'axios';
import { GrUpdate } from 'react-icons/gr';
import Autolinker from 'autolinker';
import { defaultsDeep, merge } from 'lodash';
import { notification } from 'antd';
import i18n from '../i18n';
import { API_PATH, TIME_FORMAT, PHONE_FORMAT, APP_STATE } from '../constants';
import ActiveChatStore from './ActiveChatStore';
import ChatsStore from './ChatsStore';
import MessagesStore from './MessagesStore';
import DepartmentsStore from './DepartmentsStore';
import LoginStore from './LoginStore';
import StatusStore from './StatusStore';
import NavigationStore from './NavigationStore';
import FilesStore from './FilesStore';
import NotificationsStore from './NotificationsStore';
import QuickRepliesStore from './QuickRepliesStore';
import TemplateMessagesStore from './TemplateMessagesStore';
import AgentsStore from './AgentsStore';
import DashboardStore from './DashboardStore';
import WizardStore from './WizardStore';
import CrmStore from './CrmStore';
import BulkReportsStore from './BulkReportsStore';
import ProfileStore from './ProfileStore';
import { getAxios } from '../backend';
import { getApiKey } from '../settings';
import { Events } from '../_events';
import ChannelsStore from './ChannelsStore';
import ChatNotesStore from './ChatNotesStore';
import ExportsStore from './ExportsStore';
import partnerThemePresetsJSON from '../assets/themePresetsPartner/presets.json';
import { getDirection, setCSSVariable, setDirection, shadeColor } from '../helpers';
import classNames from 'classnames';
configure({ enforceActions: 'observed' });
export const partnerThemePresets = Object.entries(partnerThemePresetsJSON).reduce((acc, [key, value], i) => {
    acc[key] = Object.assign(Object.assign({}, value), { icon: require(`../assets/themePresetsPartner/icons/${key.toLowerCase()}.png`), iconSmall: require(`../assets/themePresetsPartner/icons/${key.toLowerCase()}-small.png`) });
    return acc;
}, {});
export const generalSettingsDefault = {
    user: {
        heartbeatInterval: 5 * 60 * 1000,
        awayTimeout: 45 * 60 * 1000,
        logoutCountdownStart: 120 * 60 * 1000,
        logoutCountdownLength: 10 * 60 * 1000,
    },
    inbox: {},
    partner: {
        themes: partnerThemePresets,
    },
};
class RootStore {
    constructor() {
        // TODO: proper types after other stores typed
        Object.defineProperty(this, "agentsStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "chatsStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "departmentsStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "quickRepliesStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "templateMessagesStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "loginStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "activeChatStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "notificationsStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "wizardStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "crmStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "statusStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "chatNotesStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        // TODO: Remove "Store" suffix from all sub-stores
        // because with migration to TS type of variable shown
        Object.defineProperty(this, "messages", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "files", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "navigation", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "bulkReports", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "dashboard", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "profile", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "channels", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "exports", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "events", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "initTime", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "storage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "autolinker", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "checkBuildIdIntervalId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "checkBuildIdIntervalMsc", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 1000 * 60 * 1
        });
        Object.defineProperty(this, "_new_message_id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 'dummy_id'
        });
        Object.defineProperty(this, "_new_message_counter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(this, "generalSettings", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: Object.assign({}, generalSettingsDefault)
        });
        Object.defineProperty(this, "generalSettingsLoading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: true
        });
        Object.defineProperty(this, "appState", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: APP_STATE.READY
        });
        Object.defineProperty(this, "timeFormat", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: TIME_FORMAT.TWELVE_HOURS
        });
        Object.defineProperty(this, "phoneFormat", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: localStorage.getItem("phoneFormat") || PHONE_FORMAT.SMART
        });
        /** API to inject data to application on load
         * by enclosing WebView (mobile app, etc.) */
        Object.defineProperty(this, "webViewInjectedData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {}
        });
        Object.defineProperty(this, "tab", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                inactive: false,
            }
        });
        Object.defineProperty(this, "reset", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.navigation = new NavigationStore(this);
                this.files = new FilesStore(this);
                this.agentsStore = new AgentsStore(this);
                this.chatsStore.destroy();
                this.chatsStore = new ChatsStore(this);
                this.messages = new MessagesStore(this);
                this.departmentsStore = new DepartmentsStore(this);
                this.quickRepliesStore = new QuickRepliesStore(this);
                this.templateMessagesStore = new TemplateMessagesStore(this);
                this.activeChatStore.destroy();
                this.activeChatStore = new ActiveChatStore(this);
                this.notificationsStore = new NotificationsStore(this);
                this.wizardStore = new WizardStore(this);
                this.crmStore = new CrmStore(this);
                this.statusStore.shutdown();
                this.statusStore = new StatusStore(this);
                this.dashboard = new DashboardStore(this);
                this.bulkReports = new BulkReportsStore(this);
                this.profile = new ProfileStore(this);
                this.channels = new ChannelsStore(this);
                this.exports = new ExportsStore(this);
            }
        });
        Object.defineProperty(this, "loadGeneralSettings", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                runInAction(() => this.generalSettingsLoading = true);
                yield getAxios()
                    .then((axios) => {
                    return axios.get(API_PATH.SETTINGS);
                })
                    .then(({ data: settings }) => {
                    this.mergeSettings(settings);
                })
                    .catch((error) => {
                    console.error(error);
                })
                    .finally(() => {
                    runInAction(() => this.generalSettingsLoading = true);
                });
            })
        });
        Object.defineProperty(this, "mergeSettings", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (settings) => {
                Object.assign(this.generalSettings, defaultsDeep(settings, this.generalSettings, generalSettingsDefault));
                console.debug('General settings updated', JSON.stringify(this.generalSettings));
            }
        });
        Object.defineProperty(this, "resetSettings", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                /* remember partnerTheme since it is needed after logout */
                this.generalSettings = merge(generalSettingsDefault, {
                    partner: {
                        selectedTheme: this.generalSettings.partner.selectedTheme
                    },
                });
                console.debug('General settings reset');
            }
        });
        Object.defineProperty(this, "checkFeatureAvailability", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (feature) => {
                if (!!this.generalSettings && !!this.generalSettings.inbox && !!this.generalSettings.inbox.available_features) {
                    let obj = this.generalSettings.inbox.available_features[feature];
                    if (!!obj) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
            }
        });
        Object.defineProperty(this, "checkBuildIdVersion", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (init = false) => __awaiter(this, void 0, void 0, function* () {
                try {
                    if (['texterdev.local', 'localhost'].includes(window.location.hostname))
                        return;
                    const key = 'buildId';
                    const savedVersion = localStorage.getItem(key);
                    const buildId = yield axios
                        .get(`${window.location.origin}${API_PATH.BUILD_ID}`)
                        .then(({ data }) => data.buildId);
                    if (!buildId)
                        return;
                    if (!savedVersion || init) {
                        localStorage.setItem(key, buildId);
                        console.log('Latest build version: ', buildId);
                        return;
                    }
                    if (savedVersion !== buildId) {
                        const args = {
                            key: `notification-${key}`,
                            message: i18n.t('newVersionAvailable'),
                            description: i18n.t('newVersionAvailableInfo'),
                            className: classNames('notification-new-version', { 'rtl': getDirection() === 'rtl' }),
                            icon: React.createElement(GrUpdate, null),
                            duration: 0,
                            onClick: () => {
                                localStorage.setItem(key, buildId);
                                notification.close(`notification-${key}`);
                                window.location.reload();
                            },
                        };
                        notification.open(args);
                    }
                }
                catch (error) {
                    console.error('Error on checking the buildId version', error);
                }
            })
        });
        Object.defineProperty(this, "setInitialDirection", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (!getDirection()) {
                    setDirection(i18n.dir());
                }
            }
        });
        Object.defineProperty(this, "_startCheckBuildIdService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (this.checkBuildIdIntervalId) {
                    clearInterval(this.checkBuildIdIntervalId);
                }
                const intervalId = setInterval(() => {
                    this.checkBuildIdVersion();
                }, this.checkBuildIdIntervalMsc);
                return intervalId;
            }
        });
        Object.defineProperty(this, "setReadyState", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (newState) => {
                this.appState = newState;
            }
        });
        Object.defineProperty(this, "setTabInactive", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (inactive) => {
                this.tab.inactive = inactive;
            }
        });
        Object.defineProperty(this, "resetPassword", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (email) => __awaiter(this, void 0, void 0, function* () {
                const request = {
                    requestType: "PASSWORD_RESET",
                    email: email
                };
                const apiKey = yield getApiKey();
                yield axios.post(`https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=${apiKey}`, request)
                    .then(() => {
                    console.log("Check your email");
                }).catch(error => {
                    console.error("ERROR", error.response.data);
                    return Promise.reject(error.response.data);
                });
            })
        });
        Object.defineProperty(this, "getTimeFormat", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const timeFormat = localStorage.getItem("texterTimeFormat");
                if (timeFormat) {
                    this.timeFormat = timeFormat;
                }
            }
        });
        Object.defineProperty(this, "setTimeFormat", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                localStorage.setItem("texterTimeFormat", value);
                this.timeFormat = value;
            }
        });
        Object.defineProperty(this, "setPhoneFormat", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                localStorage.setItem("phoneFormat", value);
                this.phoneFormat = value;
            }
        });
        Object.defineProperty(this, "setPartnerTheme", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (name) => {
                this.generalSettings.partner.selectedTheme = name;
                const theme = this.getPartnerTheme();
                if (theme) {
                    setCSSVariable('--partner-theme-color', theme.color);
                    setCSSVariable('--partner-theme-color-dark', shadeColor(theme.color, -22));
                    setCSSVariable('--partner-theme-color-light', shadeColor(theme.color, 50));
                }
            }
        });
        Object.defineProperty(this, "getPartnerTheme", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { selectedTheme, themes } = this.generalSettings.partner;
                if (selectedTheme) {
                    const theme = themes[selectedTheme];
                    return theme;
                }
                return null;
            }
        });
        makeObservable(this);
        this.events = new Events();
        // Set early to have available in all stores
        if (typeof window.webViewInjectedData === 'object') {
            this.webViewInjectedData = window.webViewInjectedData;
        }
        this.navigation = new NavigationStore(this);
        this.channels = new ChannelsStore(this);
        this.files = new FilesStore(this);
        this.agentsStore = new AgentsStore(this);
        this.chatsStore = new ChatsStore(this);
        this.messages = new MessagesStore(this);
        this.departmentsStore = new DepartmentsStore(this);
        this.quickRepliesStore = new QuickRepliesStore(this);
        this.templateMessagesStore = new TemplateMessagesStore(this);
        this.loginStore = new LoginStore(this);
        // Place after LoginStore since it property listened
        this.activeChatStore = new ActiveChatStore(this);
        this.notificationsStore = new NotificationsStore(this);
        this.wizardStore = new WizardStore(this);
        this.crmStore = new CrmStore(this);
        this.chatNotesStore = new ChatNotesStore(this);
        this.bulkReports = new BulkReportsStore(this);
        this.dashboard = new DashboardStore(this);
        this.profile = new ProfileStore(this);
        this.exports = new ExportsStore(this);
        this.initTime = new Date();
        this.initTime.setMinutes(this.initTime.getMinutes() - 4); //TODO: Fix this weird bug for the listeners. If we don't subscribe minutes than the listeners doesn't invoke when loading
        this.statusStore = new StatusStore(this);
        this.autolinker = new Autolinker({
            urls: {
                schemeMatches: true,
                tldMatches: true
            },
            email: true,
            phone: true,
            mention: false,
            hashtag: false,
            stripPrefix: false,
            stripTrailingSlash: true,
            newWindow: true,
            truncate: {
                length: 0,
                location: 'end'
            },
            className: ''
        });
        /* poll every X min does app buildId match and show notification if needed */
        this.checkBuildIdIntervalId = this._startCheckBuildIdService();
        /* for fresh, first time loading */
        this.setInitialDirection();
    }
    get defaultCountry() {
        return this.generalSettings.system
            && typeof this.generalSettings.system.defaultCountry === 'string'
            ? this.generalSettings.system.defaultCountry
            : 'IL';
    }
}
__decorate([
    observable
], RootStore.prototype, "agentsStore", void 0);
__decorate([
    observable
], RootStore.prototype, "chatsStore", void 0);
__decorate([
    observable
], RootStore.prototype, "departmentsStore", void 0);
__decorate([
    observable
], RootStore.prototype, "quickRepliesStore", void 0);
__decorate([
    observable
], RootStore.prototype, "templateMessagesStore", void 0);
__decorate([
    observable
], RootStore.prototype, "loginStore", void 0);
__decorate([
    observable
], RootStore.prototype, "activeChatStore", void 0);
__decorate([
    observable
], RootStore.prototype, "notificationsStore", void 0);
__decorate([
    observable
], RootStore.prototype, "wizardStore", void 0);
__decorate([
    observable
], RootStore.prototype, "crmStore", void 0);
__decorate([
    observable
], RootStore.prototype, "statusStore", void 0);
__decorate([
    observable
], RootStore.prototype, "chatNotesStore", void 0);
__decorate([
    observable
], RootStore.prototype, "messages", void 0);
__decorate([
    observable
], RootStore.prototype, "files", void 0);
__decorate([
    observable
], RootStore.prototype, "navigation", void 0);
__decorate([
    observable
], RootStore.prototype, "bulkReports", void 0);
__decorate([
    observable
], RootStore.prototype, "dashboard", void 0);
__decorate([
    observable
], RootStore.prototype, "profile", void 0);
__decorate([
    observable
], RootStore.prototype, "channels", void 0);
__decorate([
    observable
], RootStore.prototype, "exports", void 0);
__decorate([
    observable
], RootStore.prototype, "generalSettings", void 0);
__decorate([
    observable
], RootStore.prototype, "generalSettingsLoading", void 0);
__decorate([
    observable
], RootStore.prototype, "appState", void 0);
__decorate([
    observable
], RootStore.prototype, "timeFormat", void 0);
__decorate([
    observable
], RootStore.prototype, "phoneFormat", void 0);
__decorate([
    observable
], RootStore.prototype, "tab", void 0);
__decorate([
    action
], RootStore.prototype, "reset", void 0);
__decorate([
    computed
], RootStore.prototype, "defaultCountry", null);
__decorate([
    action
], RootStore.prototype, "loadGeneralSettings", void 0);
__decorate([
    action
], RootStore.prototype, "mergeSettings", void 0);
__decorate([
    action
], RootStore.prototype, "resetSettings", void 0);
__decorate([
    action
], RootStore.prototype, "checkFeatureAvailability", void 0);
__decorate([
    action
], RootStore.prototype, "checkBuildIdVersion", void 0);
__decorate([
    action
], RootStore.prototype, "setInitialDirection", void 0);
__decorate([
    action
], RootStore.prototype, "setReadyState", void 0);
__decorate([
    action
], RootStore.prototype, "setTabInactive", void 0);
__decorate([
    action
], RootStore.prototype, "getTimeFormat", void 0);
__decorate([
    action
], RootStore.prototype, "setTimeFormat", void 0);
__decorate([
    action
], RootStore.prototype, "setPhoneFormat", void 0);
__decorate([
    action
], RootStore.prototype, "setPartnerTheme", void 0);
__decorate([
    action
], RootStore.prototype, "getPartnerTheme", void 0);
let root;
let context;
/**
 * This function made only for backward compatibility
 * with obsolete usage by mobx Provider+inject
 */
export const getStore = () => {
    if (!root) {
        root = new RootStore();
    }
    return root;
};
export const useStore = () => {
    if (!context) {
        context = createContext(getStore());
    }
    return useContext(context);
};
export function withStore(Component) {
    return function WrappedComponent(props) {
        const store = useStore();
        return React.createElement(Component, Object.assign({}, props, { store: store }));
    };
}
export default RootStore;
