import React from 'react';
import styles from './ProviderButtons.module.scss';
const MicrosoftSSOButton = ({ onClick }) => {
    return (React.createElement("div", { className: styles.microsoft },
        React.createElement("button", { onClick: onClick },
            React.createElement("svg", { viewBox: "0 0 22 22", xmlns: "http://www.w3.org/2000/svg", fill: "none" },
                React.createElement("rect", { x: "0", y: "0", width: "10", height: "10", fill: "#F35325" }),
                React.createElement("rect", { x: "12", y: "0", width: "10", height: "10", fill: "#81BC06" }),
                React.createElement("rect", { x: "0", y: "12", width: "10", height: "10", fill: "#05A6F0" }),
                React.createElement("rect", { x: "12", y: "12", width: "10", height: "10", fill: "#FFBA08" })),
            React.createElement("span", null, "Sign in with Microsoft"))));
};
export default MicrosoftSSOButton;
