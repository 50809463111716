var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component, createRef } from 'react';
import { SmileOutlined, CloseOutlined, SendOutlined } from "@ant-design/icons";
import classnames from "classnames";
import { Trans, useTranslation } from "react-i18next";
import { pick } from 'lodash';
import { Button, Dropdown, message, Tooltip, Upload } from 'antd';
import TextareaAutosize from 'react-textarea-autosize';
import EmojiPalette from "../EmojiPalette";
import { useStore } from '../../../store/rootStore';
import { clearComposeSessionState, audioRecordingSupported, getComposeSessionState, getDirection } from '../../../helpers/functions';
import { COMPOSE_SESSION_STATE_KEY } from '../../../constants';
import Reply from '../Reply';
import { GrAttachment, GrDocument, GrLocation } from 'react-icons/gr';
import { AiOutlinePicture } from 'react-icons/ai';
import { TbMicrophone } from "react-icons/tb";
import classNames from 'classnames';
import FeaturesGate from '../../FeaturesGate';
import { MessagingChannelFeature, Permissions } from '@whatsper/texterchat-common';
import useCheckIfMobile from '../../../helpers/hooks/useCheckIfMobile';
import styles from './Compose.module.scss';
;
;
class Compose extends Component {
    constructor(props) {
        super(props);
        Object.defineProperty(this, "inputRef", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "onEscape", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                const { activeReplyMessage, clearReplyMessage } = this.props.store.activeChatStore;
                if (e.key === 'Escape' && activeReplyMessage) {
                    clearReplyMessage();
                }
            }
        });
        Object.defineProperty(this, "sendTextMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                const { sendTextMessageCB, onQuickReplySelected, activeChat, isMobile } = this.props;
                const { clearReplyMessage } = this.props.store.activeChatStore;
                if (e.key === 'Enter' && !e.shiftKey && !isMobile) {
                    this.setState({ emojiPaletteOpen: false });
                    e.preventDefault();
                    if (this.state.input.length > 0) {
                        sendTextMessageCB(this.state.input);
                        this.setState({ input: "" });
                        onQuickReplySelected("");
                        clearReplyMessage();
                        clearComposeSessionState(activeChat._id);
                    }
                }
            }
        });
        Object.defineProperty(this, "sendButtonHandler", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                var _a;
                e.preventDefault();
                const { sendTextMessageCB, onQuickReplySelected, activeChat } = this.props;
                const { clearReplyMessage } = this.props.store.activeChatStore;
                if (this.state.input.length > 0 || this.props.previewMode) {
                    sendTextMessageCB(this.state.input);
                    this.setState({ input: "" });
                    onQuickReplySelected("");
                    clearReplyMessage();
                    clearComposeSessionState(activeChat._id);
                }
                /** Bring back focus to the input field */
                (_a = this.inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            }
        });
        Object.defineProperty(this, "onFilesDrop", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (info) => {
                const files = info.fileList.map(f => f.originFileObj);
                this.props.onFilesDrop(files, this.state.input);
                this.setState({ input: "" });
            }
        });
        Object.defineProperty(this, "onFilesError", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (error, file) => {
                console.log(error);
            }
        });
        Object.defineProperty(this, "onChangeHandle", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                if (this.props.quickReplyText) {
                    this.props.onQuickReplySelected("");
                }
                this.setState({ input: e.target.value }, () => {
                    var _a;
                    this.isLtr();
                    this.props.onChange(this.state.input);
                    const currentState = getComposeSessionState();
                    sessionStorage.setItem(COMPOSE_SESSION_STATE_KEY, JSON.stringify(Object.assign(Object.assign({}, currentState), { [(_a = this.props.activeChat) === null || _a === void 0 ? void 0 : _a._id]: this.state.input })));
                });
            }
        });
        Object.defineProperty(this, "onEmojiOpenHandle", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (isOpen) => {
                this.setState({ emojiPaletteOpen: isOpen });
            }
        });
        Object.defineProperty(this, "onEmojiSelectHandle", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: emoji => {
                var _a;
                this.setState({ input: this.state.input + " " + emoji.native });
                (_a = this.inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            }
        });
        Object.defineProperty(this, "onAudioRecordClick", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                var _a, _b;
                const permitted = yield audioRecordingSupported();
                if (permitted) {
                    (_b = (_a = this.props).setAudioBarOpen) === null || _b === void 0 ? void 0 : _b.call(_a, true);
                }
                else {
                    message.error(React.createElement(Trans, { i18nKey: "noMicrophonePermission" }));
                }
            })
        });
        Object.defineProperty(this, "isLtr", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (initCheck) => {
                const input = this.state.input;
                const ltr = input.match(/[a-z]+|^[\#\w]+$/gi) !== null;
                if (!initCheck && input.length > 1)
                    return;
                if (ltr) {
                    this.setState({ textDirection: "ltr" });
                }
                else {
                    this.setState({ textDirection: "rtl" });
                }
            }
        });
        Object.defineProperty(this, "uploadMenu", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                const { store, activeChat, onSendLocationClick, beforeUpload } = this.props;
                const { loading } = this.state;
                const isRTL = getDirection() === 'rtl';
                const adapter = store.channels.getAccountAdapter({
                    id: activeChat.channelInfo.accountId,
                    name: activeChat.channelInfo.name,
                });
                const mediaLimits = adapter === null || adapter === void 0 ? void 0 : adapter.messageLimits.media;
                const filesAccepts = [];
                if (mediaLimits === null || mediaLimits === void 0 ? void 0 : mediaLimits.audio)
                    filesAccepts.push(mediaLimits.audio.types);
                if (mediaLimits === null || mediaLimits === void 0 ? void 0 : mediaLimits.document)
                    filesAccepts.push(mediaLimits.document.types);
                const items = [
                    {
                        key: 'media',
                        label: (React.createElement("button", { className: classnames(styles.files, {
                                [styles.rtl]: isRTL,
                            }) },
                            React.createElement(Upload, { className: 'files-dropzone', showUploadList: false, beforeUpload: (_, files) => beforeUpload === null || beforeUpload === void 0 ? void 0 : beforeUpload(files), onChange: this.onFilesDrop, accept: Object.values(pick(mediaLimits, ['video', 'image'])).map(({ types }) => types).join(','), multiple: true },
                                React.createElement(AiOutlinePicture, { className: styles.filesImage }),
                                React.createElement("span", null,
                                    React.createElement(Trans, { i18nKey: "photosAndVideos" }))))),
                    },
                    {
                        key: 'files',
                        label: (React.createElement("button", { className: classnames(styles.files, {
                                [styles.rtl]: isRTL,
                            }) },
                            React.createElement(Upload, Object.assign({ className: 'files-dropzone', showUploadList: false, beforeUpload: (_, files) => beforeUpload === null || beforeUpload === void 0 ? void 0 : beforeUpload(files), onChange: this.onFilesDrop }, (((_a = mediaLimits === null || mediaLimits === void 0 ? void 0 : mediaLimits.document) === null || _a === void 0 ? void 0 : _a.types) && { accept: filesAccepts.join(',') }), { multiple: true }),
                                React.createElement(GrDocument, { className: styles.filesImage }),
                                React.createElement("span", null,
                                    React.createElement(Trans, { i18nKey: "document" })))))
                    },
                    {
                        key: 'location',
                        disabled: loading,
                        label: (React.createElement("button", { className: classnames(styles.files, {
                                [styles.rtl]: isRTL,
                            }), onClick: (e) => __awaiter(this, void 0, void 0, function* () {
                                e.stopPropagation();
                                this.setState({ loading: true });
                                yield (onSendLocationClick === null || onSendLocationClick === void 0 ? void 0 : onSendLocationClick());
                                this.setState({ loading: false });
                            }) },
                            React.createElement(GrLocation, { className: styles.filesImage }),
                            React.createElement("span", null,
                                React.createElement(Trans, { i18nKey: "location" })))),
                    },
                ];
                return { items, className: styles.uploadMenu };
            }
        });
        const sessionStateInput = getComposeSessionState(props.activeChat._id);
        this.state = {
            input: props.disabled ? '' : props.initialText || props.composeText || sessionStateInput,
            emojiPaletteOpen: false,
            locationWindowOpen: false,
            currentChatId: "",
            textDirection: getDirection(),
            loading: false,
        };
        this.inputRef = createRef();
    }
    componentDidUpdate(prevProps, prevState) {
        var _a, _b, _c;
        const { quickReplyText, activeChat, store, onChange } = this.props;
        if (quickReplyText && quickReplyText.localeCompare(prevProps.quickReplyText)) {
            this.setState({ input: quickReplyText });
            (_a = this.inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
        else if (!this.props.activeChat && prevProps.activeChat || this.props.activeChat && prevProps.activeChat && prevProps.activeChat._id !== this.props.activeChat._id) {
            const sessionStateInput = getComposeSessionState(activeChat._id);
            this.setState({ input: sessionStateInput, currentChatId: "", emojiPaletteOpen: false });
            (_b = this.inputRef.current) === null || _b === void 0 ? void 0 : _b.focus();
        }
        if (this.state.input !== prevState.input && this.state.input === '') {
            onChange("");
        }
        if (store.activeChatStore.activeReplyMessage && prevProps.store.activeChatStore.activeReplyMessage) {
            (_c = this.inputRef.current) === null || _c === void 0 ? void 0 : _c.focus();
        }
    }
    componentDidMount() {
        document.addEventListener('keydown', this.onEscape);
        this.isLtr(true);
    }
    componentWillUnmount() {
        document.removeEventListener('keydown', this.onEscape);
        this.props.onChange('');
    }
    render() {
        var _a, _b;
        const activeChat = this.props.activeChat;
        const { input, emojiPaletteOpen, textDirection } = this.state;
        const { t, previewMode, disabled, disabledMessage, theme, store, textMaxLength, buttonRef, maxRows, } = this.props;
        const { activeReplyMessage, clearReplyMessage, filePreview } = store.activeChatStore;
        const adapter = store.channels.getAccountAdapter({
            id: (_a = activeChat.channelInfo) === null || _a === void 0 ? void 0 : _a.accountId,
            name: (_b = activeChat.channelInfo) === null || _b === void 0 ? void 0 : _b.name,
        });
        const textLengthLimit = textMaxLength || (adapter === null || adapter === void 0 ? void 0 : adapter.messageLimits.textMaxLength);
        return (React.createElement("div", { className: classnames(styles.composeContainer, {
                [styles.previewMode]: previewMode,
                [styles.replyContainer]: !!activeReplyMessage,
            }) },
            emojiPaletteOpen && (React.createElement(EmojiPalette, { onClickOutside: () => this.onEmojiOpenHandle(false), onEmojiSelectHandle: this.onEmojiSelectHandle })),
            activeReplyMessage && !filePreview && (React.createElement(Reply, { activeChat: activeChat, activeReplyMessage: activeReplyMessage, clearReplyMessage: clearReplyMessage, color: theme.color })),
            React.createElement("div", { className: classnames(styles.compose) },
                React.createElement("div", { className: styles.leftComposeButtonsWrapper },
                    React.createElement("div", { className: classnames(styles.composeButtonWrapper, {
                            [styles.disabled]: disabled,
                        }), onClick: (e) => {
                            e.stopPropagation();
                            if (disabled)
                                return;
                            this.onEmojiOpenHandle(!emojiPaletteOpen);
                        } }, !emojiPaletteOpen ? (React.createElement(SmileOutlined, { className: styles.emojisSelectButton })) : (React.createElement(CloseOutlined, { className: styles.emojisSelectButton }))),
                    !previewMode && (React.createElement(FeaturesGate, { features: [MessagingChannelFeature.attachments] }, store.agentsStore.hasPermissions([Permissions.Permission.files_add]) && (
                    // Somewhere down this components tree, after file choses,
                    // triggered React warning:
                    // "Can't perform a React state update on an unmounted component."
                    // TODO: Fix it
                    React.createElement(Dropdown, { trigger: ["click"], placement: "topRight", menu: this.uploadMenu(), disabled: disabled },
                        React.createElement("div", { className: styles.composeButtonWrapper },
                            React.createElement("button", { className: styles.files, disabled: disabled },
                                React.createElement(GrAttachment, { className: styles.filesImage })))))))),
                React.createElement(Tooltip, { title: disabled || (textLengthLimit && input.length / textLengthLimit > 0.9)
                        ? (disabledMessage && t(disabledMessage)) || (textLengthLimit && `${input.length} / ${textLengthLimit}`)
                        : null, placement: "top" },
                    React.createElement("div", { className: styles.textAreaWrapper },
                        React.createElement(TextareaAutosize, { ref: this.inputRef, disabled: activeChat === undefined || disabled, dir: textDirection, className: styles.composeInput, placeholder: t('typeMessage'), value: input, maxRows: maxRows || 6, onChange: this.onChangeHandle, onKeyDown: this.sendTextMessage, maxLength: textLengthLimit, title: `${input.length} / ${textLengthLimit}`, autoFocus: previewMode }))),
                previewMode ? (React.createElement("div", { className: classnames(styles.composeButtonWrapper, styles.previewMode, styles.colorWhite) },
                    React.createElement("button", { className: classNames(styles.sendButton, styles.files), onClick: this.sendButtonHandler, ref: buttonRef },
                        React.createElement(SendOutlined, null)))) : (React.createElement("div", { className: styles.filesComposeButtons },
                    React.createElement("div", { className: classnames(styles.composeButtonWrapper, styles.colorWhite) }, (!this.state.input) ? (React.createElement(Button, { icon: React.createElement(TbMicrophone, null), onClick: this.onAudioRecordClick, className: styles.recordButton, shape: "circle", type: "primary" })) : (React.createElement("button", { disabled: disabled, className: classNames(styles.sendButton, styles.files), onClick: this.sendButtonHandler, ref: buttonRef },
                        React.createElement(SendOutlined, null)))))))));
    }
}
/* A way to pass {isMobile} hook result to a class component */
export default (props) => {
    const store = useStore();
    const { t } = useTranslation();
    const isMobile = useCheckIfMobile();
    return React.createElement(Compose, Object.assign({}, props, { isMobile, t, store }));
};
