import React from 'react';
import classNames from 'classnames';
import clearInputIcon from '../../../../../assets/messageStatuses/Cross.png';
import styles from './Filters.module.scss';
import { getDirection } from '../../../../../helpers';
import { MyInput } from '../../../../Base/Form';
const Filters = ({ searchValue, onSearchChanged, placeholder }) => {
    return (React.createElement("div", { className: classNames(styles.filterContainer, {
            [styles.rtl]: getDirection() === 'rtl',
        }) },
        React.createElement("div", { className: styles.filtersWrapper },
            React.createElement("div", { className: styles.search },
                React.createElement(MyInput, { value: searchValue, onChangeHandler: onSearchChanged, className: styles.searchInput, allowClear: {
                        clearIcon: (React.createElement("img", { className: classNames(styles.cleanSearchInput, {
                                [styles.rtl]: getDirection() === 'rtl',
                            }), src: clearInputIcon })),
                    }, type: "search", size: "middle", placeholder: placeholder, bordered: false, autoFocus: true })))));
};
export default Filters;
