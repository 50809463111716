import React from 'react';
import classNames from 'classnames';
import { MESSAGE_DIRECTION } from '@whatsper/texterchat-common';
import Icon, { UserOutlined } from '@ant-design/icons';
import blankProfilePicture from '../../../../../../assets/avatars/blankProfilePicture.png';
import styles from './ContactPostback.module.scss';
const ContactPostback = ({ context, name, type, incoming, invertChatColor }) => {
    const { name: contactName } = context.contacts[0];
    return (React.createElement("div", { className: classNames(styles.main, {
            [styles.reply]: type === 'reply',
        }) },
        React.createElement("div", { className: styles.info },
            React.createElement("p", { className: classNames(styles.name, {
                    [styles.incoming]: incoming,
                    [styles.invertColors]: !incoming && invertChatColor && type === 'message',
                }) }, context.direction === MESSAGE_DIRECTION.IN ? name : 'You'),
            React.createElement("div", { className: classNames(styles.text, {
                    [styles.reply]: type === 'reply',
                    [styles.invertColors]: !incoming && type === 'message',
                }) },
                React.createElement(Icon, { className: styles.userIcon, component: UserOutlined }),
                contactName.formattedName)),
        React.createElement("img", { className: styles.avatar, src: blankProfilePicture })));
};
export default ContactPostback;
