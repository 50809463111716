import React from 'react';
import classnames from 'classnames';
import { DoubleRightOutlined } from '@ant-design/icons';
import { getDirection } from '../../../helpers/functions';
import './ScrollToUnreadButton.scss';
const ScrollToUnreadButton = ({ unreadCount, handleClick }) => {
    return (React.createElement("div", { className: classnames('scroll-button-container', {
            ['rtl']: getDirection() === 'rtl',
        }) },
        unreadCount > 0 ? (React.createElement("div", { className: "unread-counter" },
            React.createElement("p", { className: "unread-counter-number" }, unreadCount))) : null,
        React.createElement("div", { className: "scroll-button", onClick: () => handleClick() },
            React.createElement(DoubleRightOutlined, { className: "scroll-down-arrow" }))));
};
export default ScrollToUnreadButton;
