import React from 'react';
import classnames from "classnames";
import { Input } from "antd";
import { observer } from 'mobx-react';
import { Trans } from "react-i18next";
import styles from "./ChatNoteInput.module.scss";
import i18n from '../../../../../i18n';
const ChatNoteInput = (props) => {
    const { isNoteFieldOpen, noteText, activeChat, handleSetText, handleAddNote, } = props;
    return (React.createElement("div", { className: classnames({
            [styles.textbox]: true,
            [styles.hiddenTextbox]: !isNoteFieldOpen
        }) },
        React.createElement(Input.TextArea, { autoSize: { minRows: 2 }, className: styles.textarea, placeholder: i18n.t('notePlaceholder'), value: noteText, onChange: (e) => { handleSetText(e.target.value); } }),
        React.createElement("button", { className: styles.addNoteButton, disabled: !activeChat, onClick: handleAddNote },
            React.createElement(Trans, { i18nKey: "addNote" }))));
};
export default observer(ChatNoteInput);
