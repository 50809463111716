import React, { useRef } from 'react';
import { getFileUrl } from '../../../../store/FilesStore';
import './AudioMessage.css';
const AudioMessage = ({ media }) => {
    const audioRef = useRef(null);
    const clickHandler = () => {
        var _a;
        (_a = audioRef.current) === null || _a === void 0 ? void 0 : _a.blur();
        let sounds = document.getElementsByTagName('audio');
        for (let i = 0; i < sounds.length; i++) {
            if (sounds[i] !== audioRef.current) {
                sounds[i].pause();
            }
        }
    };
    const getUrl = () => {
        if (media.fileId) {
            return getFileUrl(media.fileId);
        }
        else if (media.url) {
            return media.url;
        }
        else {
            console.error('Message have no file ID or URL');
        }
    };
    return (React.createElement("div", { className: "audio-container" },
        React.createElement("audio", { className: "audio-controller", src: getUrl(), controls: true, controlsList: "nodownload", ref: audioRef, onPlay: clickHandler })));
};
export default AudioMessage;
