import React from 'react';
import { Select, List } from 'antd';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useStore } from '../../../../store/rootStore';
import { MySelect } from '../../../Base/Form';
import { getDirection } from '../../../../helpers';
import allChannelsIconSvg from '../../../../assets/icons/noGroupIcons/all-chats.svg';
import s from './ChannelSelect.module.scss';
const { Option } = Select;
const ALL_CHANNELS_ID = 'all';
const ALL_CHANNELS_TITLE = 'allChannels';
const ChannelSelect = ({ className, showAll = true, showBorders = false, activeChannel, onChannelClick, accounts, disabled, visible = true, renderAsList, size = 'large', }) => {
    const { channels } = useStore();
    const { t } = useTranslation();
    const onChange = (value) => {
        let selected = null;
        if (value !== ALL_CHANNELS_ID) {
            const [name, id] = value.split('||');
            selected = {
                name: name,
                id,
            };
        }
        onChannelClick(selected);
    };
    if (!visible)
        return null;
    const allChannelsOption = (length = 0) => ({
        accountId: ALL_CHANNELS_ID,
        adapterName: 'all',
        channel: ALL_CHANNELS_TITLE,
        title: `${length}`,
    });
    if (renderAsList) {
        return (React.createElement("div", { className: className },
            React.createElement(List, { dataSource: [allChannelsOption(accounts.length), ...accounts].filter((account) => showAll ? account : account.accountId !== ALL_CHANNELS_ID), size: size === 'small' ? 'small' : 'default', renderItem: ({ title, channel, metadata, accountId }) => {
                    var _a;
                    const id = accountId === ALL_CHANNELS_ID ? accountId : `${channel}||${accountId}`;
                    return (React.createElement(List.Item, { key: id, title: title || (metadata === null || metadata === void 0 ? void 0 : metadata.accountTitle), onClick: () => onChange(id), className: classNames({
                            ['selected']: (activeChannel === null || activeChannel === void 0 ? void 0 : activeChannel.id) === accountId,
                        }) },
                        React.createElement(List.Item.Meta, { avatar: React.createElement("img", { src: accountId !== ALL_CHANNELS_ID
                                    ? (_a = channels.themes[accountId || '']) === null || _a === void 0 ? void 0 : _a.icon
                                    : allChannelsIconSvg, className: s.selectIcon }), title: React.createElement("div", { className: s.selectTitle },
                                channel,
                                " (",
                                title || (metadata === null || metadata === void 0 ? void 0 : metadata.accountTitle) || accountId,
                                ")"), className: classNames(s.listItemMetaWrapper, {
                                [s.rtl]: getDirection() === 'rtl',
                            }) })));
                }, bordered: showBorders })));
    }
    return (React.createElement("div", { className: s.container },
        React.createElement(MySelect, { value: activeChannel ? `${activeChannel.name}||${activeChannel.id}` : !showAll ? undefined : ALL_CHANNELS_ID, onChange: onChange, className: classnames(s.selectContainer, className, {
                [s.withBorders]: showBorders,
                [s.small]: size === 'small',
            }), dropdownStyle: { zIndex: 1500 }, disabled: disabled, onClick: e => {
                /* Allow filter to be open within a modal */
                e.stopPropagation();
            } }, [allChannelsOption(accounts.length), ...accounts]
            .filter((account) => (showAll ? account : account.accountId !== ALL_CHANNELS_ID))
            .map(({ title, channel, metadata, accountId }) => {
            var _a;
            const id = accountId === ALL_CHANNELS_ID ? accountId : `${channel}||${accountId}`;
            return (React.createElement(Option, { key: id, value: id, className: classNames(s.selectOption, {
                    [s.small]: size === 'small',
                }), title: title || (metadata === null || metadata === void 0 ? void 0 : metadata.accountTitle) },
                React.createElement("img", { src: accountId !== ALL_CHANNELS_ID
                        ? (_a = channels.themes[accountId || '']) === null || _a === void 0 ? void 0 : _a.icon
                        : allChannelsIconSvg, className: s.selectIcon }),
                React.createElement("div", { className: s.selectTitle },
                    t(`${channel}Channel`, { defaultValue: channel }),
                    " (",
                    title || (metadata === null || metadata === void 0 ? void 0 : metadata.accountTitle) || accountId,
                    ")")));
        }))));
};
export default observer(ChannelSelect);
