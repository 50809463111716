import React from 'react';
import { Checkbox, Tooltip } from 'antd';
import { Trans } from 'react-i18next';
import classNames from 'classnames';
import { MEDIA_TYPE, MESSAGE_DIRECTION } from '@whatsper/texterchat-common';
import DocumentMessage from '../../../../../Chat/MessagesTypes/DocumentMessage';
import ImageMessage from '../../../../../Chat/MessagesTypes/ImageMessage';
import VideoMessage from '../../../../../Chat/MessagesTypes/VideoMessage';
import { v4 as randomUUID } from 'uuid';
import s from '../FilesMediaLinks.module.scss';
const MediaItem = ({ mediaGroup, onDownloadLegacyImage, selectedFiles, onFileSelect, showCheckbox, mediaItem, color, shareFile, }) => {
    var _a, _b, _c, _d, _e;
    const { fileId, filename, mediaType, url } = mediaItem;
    const mediaMessageFormatted = { fileId: fileId === null || fileId === void 0 ? void 0 : fileId.toString(), filename, url, mediaType };
    return (React.createElement(Tooltip, { overlayStyle: { maxWidth: 300 }, placement: "right", title: !mediaItem.fileId ? (React.createElement("div", { className: s.tooltipContainer },
            React.createElement("div", { className: s.tooltipInfo },
                React.createElement(Trans, { i18nKey: "legacyFileNoGroupDownload" })),
            React.createElement("div", { className: s.tooltipAction, onClick: () => {
                    if (url) {
                        onDownloadLegacyImage(url, filename);
                    }
                } },
                React.createElement(Trans, { i18nKey: 'downloadHere' })))) : undefined },
        React.createElement("div", { className: classNames(s[`${mediaGroup}Box`], {
                [s.legacy]: !mediaItem.fileId,
            }) },
            mediaType === MEDIA_TYPE.IMAGE ? (React.createElement(ImageMessage, { messageId: randomUUID(), direction: MESSAGE_DIRECTION.OUT, media: Object.assign({}, mediaMessageFormatted), imageMessages: [Object.assign({ index: 0 }, mediaMessageFormatted)], showCaption: false, size: "small", className: classNames(s.image, {
                    [s.checkedBox]: selectedFiles.includes((_a = fileId === null || fileId === void 0 ? void 0 : fileId.toString()) !== null && _a !== void 0 ? _a : ''),
                }) })) : mediaType === MEDIA_TYPE.VIDEO ? (React.createElement(VideoMessage, { media: Object.assign({}, mediaMessageFormatted), direction: MESSAGE_DIRECTION.OUT, shareFile: shareFile, showCaption: false, size: "small", className: classNames(s.videoWrapper, {
                    [s.checkedBox]: selectedFiles.includes((_b = fileId === null || fileId === void 0 ? void 0 : fileId.toString()) !== null && _b !== void 0 ? _b : ''),
                }) })) : mediaType === MEDIA_TYPE.DOC ? (React.createElement("div", { className: classNames(s.docContentWrapper, s.checkbox, {
                    [s.checkedBox]: selectedFiles.includes((_c = fileId === null || fileId === void 0 ? void 0 : fileId.toString()) !== null && _c !== void 0 ? _c : ''),
                }) },
                React.createElement(DocumentMessage, { message: {
                        media: [mediaItem],
                    }, color: color, additionalClassName: s.docBoxMessage, shareFile: shareFile }))) : null,
            showCheckbox && (React.createElement(Checkbox, { onClick: () => {
                    if (!mediaItem.fileId)
                        return;
                    onFileSelect(fileId === null || fileId === void 0 ? void 0 : fileId.toString());
                }, className: classNames(s.mediaBoxCheckbox, s.checkbox, {
                    [s.checked]: selectedFiles.includes((_d = fileId === null || fileId === void 0 ? void 0 : fileId.toString()) !== null && _d !== void 0 ? _d : ''),
                }), checked: selectedFiles.includes((_e = fileId === null || fileId === void 0 ? void 0 : fileId.toString()) !== null && _e !== void 0 ? _e : ''), disabled: !mediaItem.fileId })))));
};
export default MediaItem;
